import Http from "./Http";

class UsersService extends Http {
  static $displayName = "UsersService";

  async getUsers(params) {
    return Promise.resolve({
      pagination: {
        totalCount: 30,
      },
      data: Array(params.limit)
        .fill(null)
        .map((_, index) => ({
          firstName: "Roman",
          lastName: "Pozdnov",
          id: index,
          email: "roman1@mail.com",
          birthday: "1998-04-11",
        })),
    });
    // return await this.get("/admins/users", { params });
  }

  async getUserById(id) {
    return Promise.resolve({
      id,
      firstName: "Roman",
      lastName: "Pozdnov",
      email: "roman1@mail.com",
      avatar: "https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg",
      dateOfBirth:
        "Sat Apr 11 1998 00:00:00 GMT+0300 (Eastern European Summer Time)",
    });
  }

  async updateUserInfo() {
    // TODO add request
    // await this.put('/', userInfo);
  }
}

export default UsersService;

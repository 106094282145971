import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";

import ToasterService from "../../services/ToastService";
import { validateFile } from "./utils/validateFile";
import { useService } from "../../base/hooks/useService";

import "./index.scss";

const DropzoneFile = ({
  onValidate = validateFile,
  onReciveFile,
  className,
  isDropContainer = true,
}) => {
  const toastr = useService(ToasterService);

  const handleAcceptedFile = useCallback((file) => {
    try {
      onValidate(file);
    } catch ({ message }) {
      toastr.error(message);
      return;
    }

    onReciveFile(file);
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!acceptedFiles.length) return;

      acceptedFiles.forEach((file) => handleAcceptedFile(file));
    },
    [handleAcceptedFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className={classnames(
        { [className]: !!className },
        { "dropzone-file": !className },
        { "drag-active": isDragActive }
      )}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} className={className} />
        {isDropContainer && (
          <div className="upload-container">
            <i className="bx bxs-cloud-upload cloud"></i>
            <p className="upload-container__text">
              Drop files here or сlick to upload
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

DropzoneFile.propTypes = {
  onValidate: PropTypes.func,
  onReciveFile: PropTypes.func,
  className: PropTypes.string,
  isDropContainer: PropTypes.bool,
};

export default DropzoneFile;

/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useLocationQuery } from "../../../../../../base/hooks/useQueryString";
import { useStoreOwnersEmployees } from "../../../../hooks/useStoreOwnersEmployees";
import { useStatefullProvider } from "../../../../../../base/hooks/useProvider";
import { TableContext } from "../../../../contexts/table";
import { CardBody, Col, Spinner } from "reactstrap";
import Table from "../../../../../../base/components/Table";
import HeaderComponent from "../../../TopTablePanel";
import { TableStructure } from "./columns";
import { useTable } from "../../../../../../base/hooks/useTable";

const LIMIT = 10;

const Employees = ({ load, total }) => {
  const { params } = useLocationQuery();

  const getCollection = useStoreOwnersEmployees();

  const filterParams = (newParams) => {
    delete newParams["offsetBilling"];
    delete newParams["query"];
    return newParams;
  };

  const [loading, pagination, list, handleTableChange] = useTable({
    getNewDataService: getCollection,
    limit: LIMIT,
    filterParams: filterParams,
  });

  const [, updateTableContext] = useStatefullProvider(TableContext);

  useEffect(() => {
    updateTableContext({
      used: total,
    });
  }, [updateTableContext, total]);

  return (
    <div className={"employees-block"}>
      {load ? (
        <div className="text-center">
          <Spinner color="info" />
        </div>
      ) : (
        <div className={"employees-table"}>
          {list.length ? (
            <Table
              data={list}
              dataStructure={TableStructure}
              loading={loading}
              HeaderComponent={HeaderComponent}
              limit={LIMIT}
              offset={params.offset || 0}
              totalCount={pagination.totalCount}
              handleTableChange={handleTableChange}
            />
          ) : (
            <CardBody>
              <div>
                <Col
                  className={"d-flex align-items-center align-content-center"}
                >
                  <div className="font-weight-semibold font-size-15 me-4 text-dark">{`Employees (${list.length})`}</div>
                </Col>
              </div>
            </CardBody>
          )}
        </div>
      )}
    </div>
  );
};

export default Employees;

/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
  DEFAULT_SUBSCRIPTION_DATE_FORMAT,
  EXPIRED,
  OTHER_DAYS,
  PENDING,
  SUBSCRIPTION_DATE_FORMAT,
  SUBSCRIPTION_DETAILS,
  TRIAL_DAYS,
} from "../../../constants/subscription";
import "./index.scss";
import DescriptionBlock from "../../../../../base/components/DescriptionBlock";
import { getFormattedDate } from "../../../helpers/format";
import moment from "moment";

const SubscriptionCard = ({ type, createdAt, registrationStatus }) => {
  const details = useMemo(() => {
    return registrationStatus !== 4
      ? PENDING
      : SUBSCRIPTION_DETAILS.find((plan) => plan.id == type) || EXPIRED;
  }, [type]);

  const expirationDate = useMemo(() => {
    return createdAt
      ? getFormattedDate(
          moment(createdAt).add(
            Number(type) !== 1 ? OTHER_DAYS : TRIAL_DAYS,
            "days"
          ),
          SUBSCRIPTION_DATE_FORMAT
        )
      : DEFAULT_SUBSCRIPTION_DATE_FORMAT;
  }, [type, createdAt]);

  return (
    <Card>
      <CardBody>
        <Row className="over-divider mb-3">
          <Col>
            <p className="font-size-16 font-weight-medium text-truncate mb-1">
              {"Subscription"}
            </p>
            <p className="font-size-13 font-weight-normal text-muted mb-0">
              {`$${details.price} / month`}
            </p>
          </Col>
          <Col className="d-flex justify-content-center">
            <div
              className={`d-flex justify-content-center align-items-center rectangle ${details.color}`}
            >
              <span className="font-size-16 font-weight-medium m-0">
                {details.title}
              </span>
            </div>
          </Col>
        </Row>
        <Row className="dropdown-divider" />
        <Row className={"mt-3"}>
          <DescriptionBlock
            title={"Date of purchase:"}
            value={
              createdAt
                ? getFormattedDate(createdAt, SUBSCRIPTION_DATE_FORMAT)
                : DEFAULT_SUBSCRIPTION_DATE_FORMAT
            }
          />
          <DescriptionBlock
            title={"Date of expiration:"}
            value={expirationDate}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default SubscriptionCard;

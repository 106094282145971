import * as yup from "yup";
import {USER_EMAIL} from "../../../../validation/user";

export const validationSchema = yup.object().shape({
  email: USER_EMAIL.required(),
});

export const initialValues = {
  email: "",
};

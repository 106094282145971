import React, { useEffect, useState, useCallback } from "react";

import MainUserInfo from "./components/MainUserInfo";
import SecondaryUserInfo from "./components/SecondaryUserInfo";

import { useService } from "../../../../base/hooks/useService";
import UsersService from "../../../../services/UsersService";
import EditUser from "./components/EditUser";

import { useUserIdFromParams } from "../../hooks";
import {useModal} from "../../../../base/hooks/useModal";
import {useLoading} from "../../../../base/hooks/useLoading";

const UserInfo = () => {
  const [user, setUser] = useState(null);
  const [isLoading, {registerPromise}] = useLoading();

  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);
  const { userId } = useUserIdFromParams();
  const {Modal, open, isOpen, getModalProps} = useModal();

  const onOpenEdit = useCallback(() => {
    open();
  }, [open]);

  const onConfirm = useCallback(
    (form) => {
        registerPromise(usersService.updateUserInfo(form));
    },
    [registerPromise, usersService]
  );

  useEffect(() => {
      registerPromise(usersService.getUserById(userId))
          .then(setUser);
  }, [registerPromise, usersService]);

    return (
    <div className="page-content">
      {user && (
        <>
          <MainUserInfo
            firstName={user.firstName}
            lastName={user.lastName}
            email={user.email}
            avatar={user.avatar}
            onOpenEdit={onOpenEdit}
          />

            <SecondaryUserInfo dateOfBirth={user.dateOfBirth}/>

            <Modal {...getModalProps()}>
                <Modal.Header {...getModalProps()}>
                    Edit user profile
                </Modal.Header>
                <Modal.Body>
                    {isOpen && (
                        <EditUser
                            user={user}
                            onEditUser={onConfirm}
                            isLoading={isLoading}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </>
      )}
    </div>
  );
};

export default UserInfo;

import {useCallback} from "react";
import * as sanitizeHtml from 'sanitize-html';

const allowedAttributes = {
    'span': ['class']
};

const generateOutput = (html) => {
    return {dangerouslySetInnerHTML: {__html: sanitizeHtml(html, {allowedAttributes})}}
} ;

export const useHighlight = (highlighted, exact = false) => {

    const decorateText = useCallback((text) => {

        if (!text || !highlighted) return generateOutput(text || "");

        const pattern = new RegExp(highlighted, 'gi');

        if (exact && highlighted !== text)return generateOutput(text);

        const html = text.replaceAll(pattern, (selectedText) => {
            return `<span class="highlighted-text">${selectedText}</span>`
        });

        return generateOutput(html)

    }, [highlighted, exact]);

    return {
        decorateText
    }
};
import { useCallback, useEffect, useState } from "react";
import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import SettingsService from "../../../services/settingsService";
import { formFields } from "../form";

export const useOnboarding = () => {
  const [initialValues, setInitialValues] = useState({
    [formFields.title]: "",
    [formFields.description]: "",
    [formFields.link]: "",
  });

  const [loading, { registerPromise }] = useLoading();

  /**
   * @type {SettingsService}
   */
  const settingsService = useService(SettingsService);

  const fetchData = useCallback(() => {
    registerPromise(settingsService.getOnboardingInfo()).then((objWithData) => {
      if (objWithData && objWithData.data) {
        setInitialValues({
          [formFields.title]: objWithData.data.title,
          [formFields.description]: objWithData.data.description,
          [formFields.link]: objWithData.data.videoLink,
        });
      }
    });
  }, [registerPromise, settingsService, setInitialValues]);

  const handleSubmit = useCallback(
    (_, values) => {
      registerPromise(settingsService.updateOnboardingInfo(values)).then(() =>
        fetchData()
      );
    },
    [registerPromise, settingsService]
  );

  useEffect(() => {
    fetchData();
  }, []);

  return [handleSubmit, initialValues, loading];
};

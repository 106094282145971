import React from 'react';
import { useField } from 'formik';
import CustomInput from './Input';
import PropTypes from 'prop-types'
import { useTranslate } from '../hooks/useTranslate';

const FormikInput = ({
    placeholder,
    name,
    type,
    maxLength,
    readOnly,
    label,
    containerClassName,
    min,
    max,
}) => {
    const [field, meta] = useField({ name, type, placeholder });
    const [translate] = useTranslate();
    const isError = meta.error && meta.touched;
    return (
        <section className={containerClassName}>
            <label className={isError ? "text-danger" : ""}>{label}</label>
            <CustomInput
                {...field}
                type={type}
                className={isError ? "is-invalid" : ""}
                maxLength={maxLength}
                readOnly={readOnly}
                placeholder={placeholder}
            />
            <span className="invalid-feedback">{translate(meta.error, { label, min, max })}</span>
        </section>
    )
};

FormikInput.propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    maxLength: PropTypes.number,
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    containerClassName: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
}

export default FormikInput;
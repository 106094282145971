import * as yup from "yup";
import {
  PASSWORD_REQUIRED,
  REPEAT_PASSWORD_REQUIRED,
} from "../../../../base/constants/forms";
import { USER_PASSWORD } from "../../../../validation/user";

export const validationSchema = yup.object().shape({
  password: USER_PASSWORD.required(PASSWORD_REQUIRED),
  repeatPassword: yup
    .string()
    .required(REPEAT_PASSWORD_REQUIRED)
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

export const initialValues = {
  password: "",
  repeatPassword: "",
};

import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Media } from "reactstrap";

import Button from "../../../../../base/components/Button";

const DEFAULT_iMAGE =
  "https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg";

const MainUserInfo = ({ firstName, lastName, email, avatar, onOpenEdit }) => {
  return (
    <Card>
      <CardBody>
        <Media>
          <div className="m-3">
            <img
              className="avatar-md rounded-circle img-thumbnail"
              src={avatar || DEFAULT_iMAGE}
            />
          </div>
          <Media body className="align-self-center">
            <div className="edit-wrapper mb-3">
              <div className="text-muted">
                <h5>{`${firstName} ${lastName}`}</h5>
                <p className="mb-1">{email}</p>
              </div>
              <Button className="edit-user-btn" onClick={onOpenEdit}>
                Edit
              </Button>
            </div>
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
};

MainUserInfo.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  onOpenEdit: PropTypes.func.isRequired,
};

export default MainUserInfo;

import { MAX_EMAIL_LENGTH } from "../../validation/lengthConstants";

export const TEXT_TYPE = "text";
export const PASSWORD_TYPE = "password";

export const ERROR_EMAIL_MESSAGE =
  "User with such email has already been registered";
export const ERROR_INVALID_CREDENTIALS_MESSAGE = "Invalid credentials";

export const ERROR_EMPTY_STRING = "";
export const EMPTY_STRING = "";

export const EMAIL_ERROR = "Email is invalid";
export const EMAIL_MAX_LENGTH_ERROR = `This field shouldn’t contain more than ${MAX_EMAIL_LENGTH} characters.`;
export const PASSWORD_PATTERN_ERROR =
  "Password should contain at least 1 digit, 1 upper and lowercase letter";

export const INCORRECT_MESSAGE = "Incorrect email or password";

export const VERIFY_CODE = "UNPROCESSABLE_ERROR"; // TODO change code from backend
export const BLOCKED_CODE = ""; // TODO

export const CODE_NAME_KEY = "code";
export const PHONE_STORAGE_KEY = "2FAPhoneNumber";

export const ERROR_SMS_CODE = "Invalid SMS code";
export const CHANGED_PHONE_KEY = "ChangedPhoneNumber";
export const CHANGED_EMAIL_KEY = "ChangedEmail";
export const DEFAULT_START_PHONE_NUMBER = "+1";
export const FIRST_PREFIX_LENGTH = 1;
export const DEFAULT_FRACTION_LENGTH = 2;

export const DEV_DEFAULT_START_PHONE_NUMBER = "+";
export const DEV_FIRST_PREFIX_LENGTH = 0;

export const INCORRECT_OLD_PASSWORD = "Incorrect old password";
export const PASSWORD_REQUIRED = "Password is required";
export const REPEAT_PASSWORD_REQUIRED = "Repeat password is required";

export const TITLE_ERROR = "Missing title";
export const DESCRIPTION_ERROR = "Missing description";
export const COLOR_ERROR = "Missing color";
export const BRAND_ERROR = "Missing brand";
export const LB_ERROR = "Please enter a valid weight (lb)";
export const OZ_ERROR = "Please enter a valid weight (oz)";
export const PRISE_ERROR = "Missing price";
export const DIMENSION_ERROR = "Incorrect dimension";
export const COST_ERROR = "Incorrect cost of item";
export const ZIP_ERROR = "Incorrect zip code";

export const CATEGORY_ERROR = "Missing category";
export const HANDLING_TIME_ERROR = "Missing handling time";
export const SHIPPING_ERROR = "Missing shipping service";
export const PAYPAL_ERROR = "Enter paypal email";

export const DEFAULT_STEP = "0.01";
export const DEFAULT_MIN_NUMBER = "0.00";
export const DEFAULT_MAX_NUMBER = "999999999.99";

export const DRAFT_KEY_TAGS = "tags";
export const DRAFT_TAGS_KEY_NAME = "name";
export const DRAFT_KEY_BRAND = "brand";

export const MIN_VALIDATION_NUMBER = 0;
export const MAX_VALIDATION_NUMBER = 999_999_999.99;

export const MAX_LENGTH_SKU = 50;
export const MAX_LENGTH_ZIP = 5;
export const MAX_LENGTH_TAGS = 800;

/* eslint-disable react/prop-types */
import React from "react";
import { BreadcrumbItem, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

const Breadcrumbs = (props) => {
  const history = useHistory();
  return (
    <Row>
      <div className="page-title-right">
        <ol className="breadcrumb m-0">
          <BreadcrumbItem
            onClick={() => history.push(props.prevRoute)}
            className="cursor-pointer"
          >
            {props.prevTitle}
          </BreadcrumbItem>
          {props.children}
          <BreadcrumbItem active>{props.currentTitle}</BreadcrumbItem>
        </ol>
      </div>
    </Row>
  );
};

export default Breadcrumbs;

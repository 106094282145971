import React from "react";
import {Card} from "reactstrap";
import {useTotalStoreOwnersEmployees} from "../../../hooks/useStoreOwnersEmployees";
import BillingHistoryList from "./BillingHistoryList";

const BillingHistory = () => {
    const [{ load }] = useTotalStoreOwnersEmployees();
    return(
        <Card>
            <BillingHistoryList load={load} />
        </Card>
    )
}

export default BillingHistory
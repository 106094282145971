import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

import Paypal from "./Paypal";

const FIRST_TAB = "Paypal";

export default function PaymentTabs() {
  const [activeTab, setActiveTab] = useState(FIRST_TAB);

  function toggleCustom(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <section>
      <Nav tabs className="nav-tabs-custom nav-justified">
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === FIRST_TAB,
            })}
            onClick={() => {
              toggleCustom(FIRST_TAB);
            }}
          >
            <span className="d-none d-sm-block">Credit Card</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === FIRST_TAB,
            })}
            onClick={() => {
              toggleCustom(FIRST_TAB);
            }}
          >
            <span className="d-none d-sm-block">Paypal</span>
          </NavLink>
        </NavItem>
      </Nav>
      <br />
      <TabContent activeTab={activeTab}>
        <TabPane tabId={FIRST_TAB}>
          <Paypal />
        </TabPane>
      </TabContent>
    </section>
  );
}

import yup from "../../../../validation/yup";


export const validationSchema = yup.object().shape({
	firstName: yup.string()
		.required(),
	lastName: yup.string()
		.required(),
	birthday: yup.string()
		.required(),
});

export const initialValues = {
	firstName: '',
	lastName: '',
	birthday: '',
	email: '',
}
import React from "react";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";

export const ModalBody = ({ children }) => (
  <div className="modal-body">{children}</div>
);

ModalBody.propTypes = {
  children: PropTypes.any,
};

export const ModalHeader = ({
  onClose,
  className = "modal-title mt-0 font-size-15 font-weight-semibold",
  children,
}) => (
  <div className="modal-header">
    {className ? (
      <span className={className} id="myModalLabel">
        {children}
      </span>
    ) : (
      <h3 className={className} id="myModalLabel">
        {children}
      </h3>
    )}
    <button
      type="button"
      onClick={onClose}
      className="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true"></span>
    </button>
  </div>
);

ModalHeader.propTypes = {
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
};

export const CustomModal = ({ isOpen, onClose, children }) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} size="md" centered={true}>
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export const ModalFooter = ({ children }) => (
  <div className="modal-footer">{children}</div>
);

ModalFooter.propTypes = {
  children: PropTypes.any,
};

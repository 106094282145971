/* eslint-disable no-console */
import React, { useState, useCallback } from "react";
import { CardBody, Card } from "reactstrap";
import PropTypes from "prop-types";

import styles from "./index.module.scss";
import { ReactComponent as Image } from "./images/ic-link-red.inline.svg";

import AuthService from "../../../../services/AuthService";
import { useService } from "../../../../base/hooks/useService";

const ExpiredLink = ({ token }) => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  const onResendLink = useCallback(async () => {
    try {
      setIsLoading(true);
      authService.resendChangePasswordLink({ token });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div className={styles.container}>
      <Card className="overflow-hidden">
        <CardBody className="p-0">
          <div className={styles.wrapper}>
            <div className={styles.imageWrapper}>
              <Image />
            </div>
            <span className={styles.text}>The link is expired!</span>
            <button
              type="button"
              className={styles.button}
              onClick={onResendLink}
              disabled={isLoading}
            >
              Resend link
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

ExpiredLink.propTypes = {
  token: PropTypes.string,
};

export default ExpiredLink;

/* eslint-disable react/prop-types */
import moment from "moment";
import React, { useCallback } from "react";
import { ACTIVE_SUBSCRIPTION_TYPE } from "../constants/table";
import { useStatelessProvider } from "../../../base/hooks/useProvider";
import { TableContext } from "../contexts/table";

export const getFormattedDate = (date, format) => {
  return moment(date).format(format);
};

export const getFormattedSubscription = (
  list = ACTIVE_SUBSCRIPTION_TYPE,
  type
) => {
  return list[type] || "Expired";
};

export const getFormattedAmount = (amount) => {
  return (Number(amount) / 100).toFixed(2);
};

export const FormatDate = (date, format) => {
  return (
    <span className={"table-cell-date"}>{getFormattedDate(date, format)}</span>
  );
};

export const FormatSubscription = (list = ACTIVE_SUBSCRIPTION_TYPE, type) => {
  return (
    <span className={"table-cell-sub"}>
      {getFormattedSubscription(list, type)}
    </span>
  );
};

export const FormatActiveSubscription = (registrationStatus, type) => {
  return (
    <span className={"table-cell-sub"}>
      {registrationStatus !== 4
        ? "Pending"
        : getFormattedSubscription(ACTIVE_SUBSCRIPTION_TYPE, type)}
    </span>
  );
};

export const FormatAction = ({ id, isBlocked }) => {
  const [{ onUpdateBlockedStatus }] = useStatelessProvider(TableContext);

  const handleUpdateBlockedStatus = useCallback(() => {
    onUpdateBlockedStatus(id, isBlocked);
  }, [id, isBlocked, onUpdateBlockedStatus]);

  return (
    <div className="d-flex justify-content-center">
      <span
        className={`badge rounded-pill bg-${
          isBlocked ? "success" : "danger"
        } cursor-pointer`}
        onClick={handleUpdateBlockedStatus}
      >
        {isBlocked ? "Unblock" : "Block"}
      </span>
    </div>
  );
};

export const FormatAmount = (amount) => {
  return (
    <span className={"table-cell-amount"}>
      {"$"}
      {getFormattedAmount(amount)}
    </span>
  );
};

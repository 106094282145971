import { setLocale } from 'yup';

setLocale({
    mixed: {
        required: 'ERROR_MESSAGES.REQUIRED',
    },
    string: {
        email: "ERROR_MESSAGES.EMAIL",
        trim: "ERROR_MESSAGES.TRIM",
        min:  "ERROR_MESSAGES.MIN",
        max: "ERROR_MESSAGES.MAX",
        typeError: "ERROR_MESSAGES.TYPE_ERROR",
    },
    number: {
        min: 'ERROR_MESSAGES.MIN',
        max: 'ERROR_MESSAGES.MAX',
        typeError: "ERROR_MESSAGES.TYPE_ERROR",
    },
});

import * as yup from 'yup';

export default yup;
import { useService } from "../../../base/hooks/useService";
import StoreOwnersService from "../../../services/StoreOwnersService";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

export const useStoreOwnersEmployees = (limit = 10) => {
  const { storeOwnerId } = useParams();
  /**
   * @type {StoreOwnersService}
   */
  const storeOwnersService = useService(StoreOwnersService);

  return useCallback(
    ({ offset }) => {
      return storeOwnersService.getStoreOwnersEmployees(storeOwnerId, {
        limit,
        offset,
      });
    },
    [storeOwnerId, storeOwnersService, limit]
  );
};

export const useTotalStoreOwnersEmployees = () => {
  const load = useStoreOwnersEmployees(1);
  const [state, update] = useState({ total: 0, load: true });

  useEffect(() => {
    load({})
      .then(({ pagination }) => {
        update({ load: false, total: pagination.totalCount });
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, [load]);

  return [state, update];
};

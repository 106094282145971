import React from "react";
import {Container} from "reactstrap";
import PageMeta from "../../../../base/components/MetaTags";
import StoreOwnerDetails from "../../components/StoreOwnerDetails";
import Breadcrumbs from "../../../../base/components/Breadcrumbs";
import {LINK_TO_STORE_OWNERS} from "../../../../base/constants/links";

const StoreOwnerById = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <PageMeta title={"User details"}/>
                <div className="mb-4 d-flex justify-content-between">
                    <h4 className="font-weight-semibold font-size-16">USER DETAILS</h4>
                    <Breadcrumbs
                        prevRoute={LINK_TO_STORE_OWNERS}
                        prevTitle={"Users Management"}
                        currentTitle={"User details"}
                    />
                </div>

                <div className={"store-owners-details-page"}>
                    <StoreOwnerDetails/>
                </div>
            </Container>
        </div>
    )
}

export default StoreOwnerById;
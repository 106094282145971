/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Col } from "reactstrap";

const HeaderComponentBillingHistory = ({ toolkitProps }) => {
  return (
    <div
      className={
        "d-flex store-owners-list-toolbar justify-content-between align-items-center"
      }
    >
      <Col className={"d-flex align-items-center align-content-center"}>
        <div className="font-weight-semibold font-size-15 me-4">
          {"Billing history"}
        </div>
      </Col>
    </div>
  );
};

export default HeaderComponentBillingHistory;

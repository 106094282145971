import CookiesPolicy from './pages/CookiesPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermPolicy from './pages/TermPolicy';
import {Route, Switch, Redirect} from "react-router-dom";
import React from "react";
import {PRIVACY_GROUP_LINKS} from "./config";

const Privacy = () => {
  return (<Switch>
      <Route path={PRIVACY_GROUP_LINKS.LINK_PRIVACY_POLICY} component={PrivacyPolicy}/>
      <Route path={PRIVACY_GROUP_LINKS.LINK_TERMS} component={TermPolicy}/>
      <Route path={PRIVACY_GROUP_LINKS.LINK_COOKIES} component={CookiesPolicy}/>
      <Redirect to={PRIVACY_GROUP_LINKS.LINK_PRIVACY_POLICY}/>
    </Switch>
  )
};


export default Privacy
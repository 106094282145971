import React from "react";

import Table from "../../../../base/components/Table";

import UsersService from "../../../../services/UsersService";
import { useService } from "../../../../base/hooks/useService";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useTable } from '../../../../base/hooks/useTable';

import tableStructure from "./tableStructure";
import UsersTableHeader from './TableHeader';
import { Container } from "reactstrap";

const USERS_LIMIT = 10;

export const UsersList = () => {
  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);

  const [loading, pagination, data, handleTableChange] = useTable({
    searchQueryParam: "query",
    sortTypeQueryParam: 'sortType',
    sortByQueryParam: 'sortBy',
    getNewDataService: usersService.getUsers,
    limit: USERS_LIMIT,
  })

  const { params } = useLocationQuery();

  return (
    <section className="page-content">
      <Container fluid>
        <Table
          data={data}
          dataStructure={tableStructure}
          loading={loading}
          HeaderComponent={UsersTableHeader}
          limit={USERS_LIMIT}
          offset={params.offset || 0}
          totalCount={pagination.totalCount}
          handleTableChange={handleTableChange}
        />
      </Container>
    </section>
  );
};

import React, { useState, useCallback } from "react";
import { Card, CardBody } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import classnames from "classnames";

import profile from "../../assets/profile-img.png";

import FormikInput from "../../../../base/components/FormikInput";
import { useService } from "../../../../base/hooks/useService";

import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";
import CustomButton from "../../../../base/components/CustomButton";
import AuthService from "../../../../services/AuthService";

import { initialValues, validationSchema } from "./form";
import { useLoading } from "../../../../base/hooks/useLoading";
import { AUTH_GROUP_LINKS } from "../../config";

import styles from "./index.module.scss";

const ForgetPasswordPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, { registerPromise }] = useLoading();
  const history = useHistory();
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  const sendEmailLink = (email) => {
    handleToggleModal("", true);
    registerPromise(authService.resendChangePasswordLink(email));
  };

  const handleToggleModal = useCallback(
    (_, isOpen = false) => {
      setIsOpenModal(isOpen);
    },
    [setIsOpenModal]
  );

  const redirectToLogin = useCallback(() => {
    history.push(AUTH_GROUP_LINKS.LINK_LOGIN);
  }, [history]);

  return (
    <>
      <AuthCardContainer metaText="Reset password">
        <Card className="overflow-hidden">
          <AuthHeader headerText="Reset password" icon={profile} />
          <CardBody className="pt-0">
            <Logo />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                sendEmailLink(values);
              }}
            >
              {({ errors, values, handleSubmit }) => (
                <form
                  className={classnames(
                    "form-horizontal p-2",
                    styles.formWrapper
                  )}
                  onSubmit={handleSubmit}
                >
                  <FormikInput
                    id="email"
                    name="email"
                    label="Email"
                    containerClassName="mb-3"
                    placeholder="Enter email"
                    type="email"
                  />
                  <button
                    className={classnames(
                      "btn btn-primary waves-effect waves-light",
                      styles.resentButton
                    )}
                    type="submit"
                    disabled={
                      isLoading ||
                      Object.keys(errors).length ||
                      values?.email?.length === 0
                    }
                  >
                    Send restore link
                  </button>
                </form>
              )}
            </Formik>
          </CardBody>
        </Card>
        <div className="mt-4 text-center">
          <p>
            Remember It ?{" "}
            <Link
              to={AUTH_GROUP_LINKS.LINK_LOGIN}
              className="font-weight-medium text-primary"
            >
              Sign in here
            </Link>
          </p>
        </div>
      </AuthCardContainer>
      <CustomModal isOpen={isOpenModal} onClose={redirectToLogin}>
        <ModalHeader className={styles.modalTitle} onClose={redirectToLogin}>
          Reset password
        </ModalHeader>
        <ModalBody>
          <p className="ui-regular">
            The link for password restoration was sent to your email.
          </p>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            className={styles.modalButton}
            onClick={redirectToLogin}
          >
            Ok
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default ForgetPasswordPage;

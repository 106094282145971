export const STRINGS = {
  CROPPER: {
    cropBtn: "Get Cropped Canvas",
    closeBtn: "Close",
    format16x9: "16:9",
    format4x3: "4:3",
    format1x1: "1:1",
    format2x3: "2:3",
    formatFree: "Free",
  },
};

import { useService } from "../../../base/hooks/useService";
import StoreOwnersService from "../../../services/StoreOwnersService";
import { useCallback, useEffect, useState } from "react";

export const useStoreOwners = (limit = 10) => {
  /**
   * @type {StoreOwnersService}
   */
  const storeOwnersService = useService(StoreOwnersService);

  return useCallback(
    ({ orderType, orderBy, subscriptionPlanFilter, offset, query: q }) => {
      return storeOwnersService.getStoreOwnersList({
        limit,
        orderBy,
        orderType,
        offset,
        q,
        subscriptionPlanFilter,
      });
    },
    [storeOwnersService, limit]
  );
};

export const useTotalStoreOwners = (limit = 10) => {
  const getStoreOwners = useStoreOwners(limit);
  const [state, update] = useState({ total: 0, isLoading: true });

  useEffect(() => {
    getStoreOwners({}).then(({ pagination }) => {
      update({ isLoading: false, total: pagination.totalCount });
    });
  }, [getStoreOwners]);

  return [state, update];
};

import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";
import classNames from "classnames";
import { useField } from "formik";

const OnboardingLabel = ({ title, fieldName }) => {
  const [, meta] = useField({ name: fieldName });

  const isError = meta.error && meta.touched;

  return (
    <span className={classNames(styles.label, { [styles.error]: isError })}>
      {title}
    </span>
  );
};

OnboardingLabel.propTypes = {
  title: PropTypes.string,
  fieldName: PropTypes.string,
};

export default OnboardingLabel;

/* eslint-disable react/prop-types */
import { Col } from "reactstrap";
import React from "react";

const DescriptionBlock = ({ title, value, size = "14" }) => {
  return (
    <Col>
      <p className={`font-weight-medium font-size-${size} mb-1`}>{title}</p>
      <p className="font-weight-normal font-size-13 text-muted mb-0">{value}</p>
    </Col>
  );
};

export default DescriptionBlock;

import {useHistory, useLocation} from "react-router-dom";
import {useCallback, useState} from "react";
import qs from "query-string";
import {parseQuery} from "../../../base/hooks/useQueryString";

export const useFilters = (listFilters) => {
    const {search} = useLocation();
    const history = useHistory();

    const getAllQueryParams = useCallback((query) => {
        return parseQuery(query)
    }, []);

    const isHasFilter = useCallback(() => {
        const searchParams = getAllQueryParams(search)
        const searchValue = listFilters.map((element) => {return searchParams[element]})
        return searchValue.some(value => value !== undefined)
    }, [search, getAllQueryParams])

    const [isFilterActive, setIsFilterActive] = useState(isHasFilter())

    const mergeQuery = useCallback((options) => {
        history.push({
            search: qs.stringify({
                ...getAllQueryParams(search),
                ...options,
                offset: 0
            })
        })
        setIsFilterActive(true)
    }, [history, getAllQueryParams, search])

    const resetFilters = useCallback(() => {
        const newParams = {};
        listFilters.forEach((index) => {
            newParams[index] = undefined
        })
        newParams.offset = 0;
        mergeQuery(newParams)
        setIsFilterActive(false)
    }, [listFilters, mergeQuery])

    return {getAllQueryParams, mergeQuery, isFilterActive, resetFilters}
}
import * as yup from "yup";

import {
  DESCRIPTION_ERROR,
  LINK_FORMAT_ERROR,
  LINK_LENGTH_ERROR,
  LINK_PATTERN,
  MAX_DESCRIPTION,
  MAX_LINK,
  MAX_TITLE,
  MIN_DESCRIPTION,
  MIN_LINK,
  MIN_TITLE,
  TITLE_ERROR,
} from "./constants";

export const formFields = {
  title: "title",
  description: "description",
  link: "videoLink",
};

export const validationSchema = yup.object().shape({
  [formFields.title]: yup
    .string()
    .required(TITLE_ERROR)
    .min(MIN_TITLE, TITLE_ERROR)
    .max(MAX_TITLE, TITLE_ERROR)
    .trim(),
  [formFields.description]: yup
    .string()
    .required(DESCRIPTION_ERROR)
    .min(MIN_DESCRIPTION, DESCRIPTION_ERROR)
    .max(MAX_DESCRIPTION, DESCRIPTION_ERROR)
    .trim(),
  [formFields.link]: yup
    .string()
    .required(LINK_LENGTH_ERROR)
    .min(MIN_LINK, LINK_LENGTH_ERROR)
    .max(MAX_LINK, LINK_LENGTH_ERROR)
    .matches(LINK_PATTERN, LINK_FORMAT_ERROR)
    .trim(),
});

import Http from "./Http";

class StoreOwnersService extends Http {
  constructor(props) {
    super(props);
  }

  getStoreOwnersList(model) {
    return this.get("/admins/users/store-owners", { params: model });
  }

  getStoreOwner(id) {
    return this.get(`/admins/users/store-owners/${id}`);
  }

  getStoreOwnersEmployees(id, model) {
    return this.get(`/admins/users/store-owners/${id}/employees`, {
      params: model,
    });
  }

  deleteStoreOwner(id) {
    return this.delete(`/admins/users/store-owners/${id}`);
  }

  changeBlockedStatusStoreOwner(id, model) {
    return this.patch(`/admins/users/store-owners/${id}/is-blocked`, model);
  }

  getStoreOwnerTransaction(id, model) {
    return this.get(`/admins/users/store-owners/${id}/transactions`, {
      params: model,
    });
  }

  static $displayName = "StoreOwnersService";
}

export default StoreOwnersService;

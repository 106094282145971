import React, { useCallback, useState } from "react";
import { CardBody, Card, Alert, Label } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import FormikInput from "../../../../base/components/FormikInput";
import { Formik } from "formik";

import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";

import { useService } from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import { useRequestErrorMessage } from "../../../../base/hooks/useRequestErrorMessage";
import { useLoading } from "../../../../base/hooks/useLoading";
import StorageService from "../../../../services/StorageService";
import SessionStorage from "../../../../services/SessionStorage";
import { KEY_USER } from "../../../../base/constants/storage";
import Button from "../../../../base/components/Button/index";
import {
  BUTTON_COLORS,
  BUTTON_STYLES,
} from "../../../../base/components/Button/appearance";
import { initialValues, validationSchema } from "./form";
import { AUTH_GROUP_LINKS } from "../../config";

const Login = () => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  /**
   * @type {SessionStorage}
   */
  const storageSession = useService(SessionStorage);

  const history = useHistory();

  const [alertText, { onCatchError }] = useRequestErrorMessage();
  const [isLoading, { registerPromise }] = useLoading();
  const [isRememberMe, setIsRememberMe] = useState(false);

  const loginUser = useCallback(
    (values) => {
      registerPromise(authService.login(values))
        .then(({ data: { user, session } }) => {
          storage.set(KEY_USER, user);
          storageSession.setRememberMe(isRememberMe);
          storageSession.setSession(session);
          history.push(AUTH_GROUP_LINKS.EXTERNAL.AFTER_LOGIN);
        })
        .catch(onCatchError);
    },
    [
      registerPromise,
      authService,
      storageSession,
      storage,
      isRememberMe,
      history,
    ]
  );

  return (
    <AuthCardContainer metaText="Login">
      <Card className="overflow-hidden">
        <AuthHeader headerText="Sign in" />
        <CardBody className="pt-0">
          <Logo />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              loginUser(values);
            }}
          >
            {({ errors, handleSubmit }) => (
              <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                {alertText ? <Alert color="danger">{alertText}</Alert> : null}

                <FormikInput
                  id="email"
                  name="email"
                  label="Email"
                  containerClassName="mb-3"
                  placeholder="Enter email"
                  type="email"
                />

                <FormikInput
                  id="password"
                  label="Password"
                  name="password"
                  containerClassName="mb-3"
                  type="password"
                  placeholder="Enter password"
                />

                <div className="form-check mt-3">
                  <input
                    type={"checkbox"}
                    className="form-check-input"
                    name="rememberMe"
                    id={"remember"}
                    checked={isRememberMe}
                    onChange={(event) => setIsRememberMe(event.target.checked)}
                  />
                  <Label className="form-check-label" for={"remember"}>
                    Remember me
                  </Label>
                </div>

                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  block={BUTTON_STYLES.block}
                  className="mt-3"
                  disabled={isLoading || !!Object.keys(errors).length}
                >
                  Log In
                </Button>

                <div className="mt-4 text-center">
                  <Link
                    to={AUTH_GROUP_LINKS.LINK_FORGOT_PASSWORD}
                    className="text-muted"
                  >
                    <i className="mdi mdi-lock me-1 font-size-16" />
                    Forgot your password?
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </AuthCardContainer>
  );
};

export default Login;

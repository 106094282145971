import axios from "axios";

import { refreshSessionInterceptor } from "./interceptors/refreshSessionInterceptor";
import { mapResponse } from "./interceptors/mapResponse";
import { csrfRequestInterceptor } from "./interceptors/csrfRequestInterceptor";

const interceptorsResponse = [mapResponse, refreshSessionInterceptor];

const interceptorsRequest = [csrfRequestInterceptor];

const headers = {
  "Access-Control-Allow-Origin": "*",
};

class Http {
  constructor() {
    this.instance = Http.createInstance({
      baseURL: `${Http.api.common}/${Http.versions.v1}${this.getURLScope()}`,
      headers,
      withCredentials: true,
    });
    this.instance.interceptors.request.use(...interceptorsRequest);
    this.instance.interceptors.response.use(...interceptorsResponse);
  }
  static $displayName = "Http";

  static createInstance() {
    return axios.create.apply(axios, arguments);
  }

  getURLScope() {
    return "";
  }

  get() {
    return this.instance.get.apply(this.instance, arguments);
  }

  patch() {
    return this.instance.patch.apply(this.instance, arguments);
  }

  put() {
    return this.instance.put.apply(this.instance, arguments);
  }

  post() {
    return this.instance.post.apply(this.instance, arguments);
  }

  delete() {
    return this.instance.delete.apply(this.instance, arguments);
  }
}

Http.api = {
  common: process.env.REACT_APP_BASE_URL || "https://api.stage.fearn.co/api",
};

Http.versions = {
  v1: process.env.REACT_APP_API_VERSION || "v1",
};

export default Http;

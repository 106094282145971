import React, { useEffect, useCallback, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { CardBody, Card } from "reactstrap";
import { Formik } from "formik";
import classNames from "classnames";

import FormikInput from "../../../../base/components/FormikInput";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useService } from "../../../../base/hooks/useService";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";
import CustomButton from "../../../../base/components/CustomButton";

import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";

import AuthService from "../../../../services/AuthService";
import ToasterService from "../../../../services/ToastService";

import { validationSchema, initialValues } from "./form";
import { useLoading } from "../../../../base/hooks/useLoading";
import { AUTH_GROUP_LINKS } from "../../config";

import styles from "./index.module.scss";
import ExpiredLink from "../ExpiredLink";

export default function ResetPassword() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isExpiredLink, setIsExpiredLink] = useState(false);

  const history = useHistory();
  const [isLoading, { registerPromise }] = useLoading();

  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  /**
   * @type {ToasterService}
   */
  const toastr = useService(ToasterService);

  const {
    params: { token },
  } = useLocationQuery();

  const resetPassword = ({ password }) => {
    setIsOpenModal(true);
    registerPromise(
      authService.resetPassword({
        password,
        token,
      })
    );
  };

  const checkLinkRelevance = useCallback(() => {
    registerPromise(
      authService.checkLinkRelevance({ token }).catch(() => {
        setIsExpiredLink(true);
        toastr.error("Link has expired");
      })
    );
  }, [token, registerPromise]);

  const redirectToLogin = useCallback(() => {
    history.push(AUTH_GROUP_LINKS.LINK_LOGIN);
  }, [history]);

  useEffect(() => {
    checkLinkRelevance();
  }, [checkLinkRelevance]);

  return (
    <>
      {isExpiredLink ? (
        <ExpiredLink token={token} />
      ) : (
        <>
          <AuthCardContainer metaText="Login">
            <Card className="overflow-hidden">
              <AuthHeader headerText="Create new password" />
              <CardBody className="pt-0">
                <Logo />
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    resetPassword(values);
                  }}
                >
                  {({ errors, values, handleSubmit }) => (
                    <form
                      className={classNames(
                        "form-horizontal p-2",
                        styles.formWrapper
                      )}
                      onSubmit={handleSubmit}
                    >
                      <FormikInput
                        id="password"
                        label="New password"
                        name="password"
                        containerClassName="mb-3"
                        type="password"
                        placeholder="Enter new password"
                      />
                      <FormikInput
                        id="repeatPassword"
                        label="Confirm new password"
                        name="repeatPassword"
                        containerClassName="mb-3"
                        type="password"
                        placeholder="Confirm new password"
                      />
                      <button
                        className={classNames(
                          "btn btn-primary waves-effect waves-light",
                          styles.confirmButton
                        )}
                        type="submit"
                        disabled={
                          isLoading ||
                          Object.keys(errors).length ||
                          values?.password?.length === 0 ||
                          values?.repeatPassword?.length === 0
                        }
                      >
                        Confirm
                      </button>
                    </form>
                  )}
                </Formik>
              </CardBody>
            </Card>
            <div className="mt-4 text-center">
              <p>
                Remember It ?{" "}
                <Link
                  to={AUTH_GROUP_LINKS.LINK_LOGIN}
                  className="font-weight-medium text-primary"
                >
                  Sign in here
                </Link>
              </p>
            </div>
          </AuthCardContainer>
          <CustomModal isOpen={isOpenModal} onClose={redirectToLogin}>
            <ModalHeader
              className={styles.modalTitle}
              onClose={redirectToLogin}
            >
              Reset password
            </ModalHeader>
            <ModalBody>
              <p className="ui-regular">
                Your password has been updated successfully.
              </p>
            </ModalBody>
            <ModalFooter>
              <CustomButton
                className={styles.modalButton}
                onClick={redirectToLogin}
              >
                Ok
              </CustomButton>
            </ModalFooter>
          </CustomModal>
        </>
      )}
    </>
  );
}

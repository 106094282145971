import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col md={10}>
            </Col>
            <Col>{new Date().getFullYear()} © Fearn</Col>
          </Row>
        </Container>
      </footer>
  )
}

export default Footer

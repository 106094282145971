import VerticalLayout from "../../base/components/VerticalLayout";
import { Redirect, Route, Switch } from "react-router-dom";
import Profile from "../profile";
import Users from "../users";
import Settings from "../payments";
import React, { useMemo } from "react";

import ErrorBoundary from "../../base/components/ErrorBoundary";
import { USERS_GROUP_LINKS } from "../users/config";
import { PROFILE_GROUP_LINKS } from "../profile/config";
import { PAYMENTS_GROUP_LINKS } from "../payments/config";
import { ONBOARDING_GROUP_LINKS } from "../onboarding/config";
import Onboarding from "../onboarding";
import { LINK_TO_STORE_OWNERS } from "../../base/constants/links";
import StoreOwnersRoutes from "../storeowners";

const AppRoutes = () => {
  const schema = useMemo(
    () => [
      {
        label: "Users Management",
        linkTo: LINK_TO_STORE_OWNERS,
        icon: "bx bx-user-circle",
      },
      {
        label: "Onboarding instruction",
        linkTo: ONBOARDING_GROUP_LINKS.BASE,
        icon: "bx bx-detail",
      },
    ],
    []
  );

  return (
    <VerticalLayout schema={schema}>
      <ErrorBoundary>
        <Switch>
          <Route path={PROFILE_GROUP_LINKS.BASE}>
            <Profile />
          </Route>
          <Route path={LINK_TO_STORE_OWNERS} component={StoreOwnersRoutes} />
          <Route path={USERS_GROUP_LINKS.BASE} component={Users} />
          <Route path={PAYMENTS_GROUP_LINKS.BASE} component={Settings} />
          <Route path={ONBOARDING_GROUP_LINKS.BASE} component={Onboarding} />
          <Redirect to={LINK_TO_STORE_OWNERS} />
        </Switch>
      </ErrorBoundary>
    </VerticalLayout>
  );
};

export default AppRoutes;

import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import classNames from "classnames";

import styles from "./index.module.scss";

const OnboardingInput = ({
  name,
  onChange = () => {},
  placeholder,
  isTextarea = false,
}) => {
  const [field, meta, { setTouched }] = useField({ name });

  const isError = meta.error && meta.touched;

  const handleOnChange = (event) => {
    setTouched(true);
    field.onChange(event);
    onChange(event);
  };

  return (
    <>
      {isTextarea ? (
        <textarea
          className={classNames(styles.textarea, {
            [styles.errorTextarea]: isError,
          })}
          name={name}
          value={field.value}
          onChange={handleOnChange}
          placeholder={placeholder}
        />
      ) : (
        <input
          className={classNames(styles.input, { [styles.errorInput]: isError })}
          name={name}
          value={field.value}
          onChange={handleOnChange}
          placeholder={placeholder}
        />
      )}

      {isError && <span className={styles.error}>{meta.error}</span>}
    </>
  );
};

OnboardingInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isTextarea: PropTypes.bool,
};

export default OnboardingInput;

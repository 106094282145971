import React from "react";
import { Container, Card, CardBody, Spinner } from "reactstrap";
import { Formik } from "formik";
import { isEmpty } from "lodash";

import styles from "./index.module.scss";

import OnboardingInput from "./components/OnboardingInput";
import OnboardingLabel from "./components/OnboardingLabel";

import { formFields, validationSchema } from "./form";

import { useOnboarding } from "./hooks/useOnboarding";

import PageMeta from "../../base/components/MetaTags";

const Onboarding = () => {
  const [handleSubmit, initialValues, loading] = useOnboarding();

  return (
    <section className="page-content">
      <PageMeta title="Onboarding instruction" />
      <Container fluid>
        <Card className="p-3">
          <CardBody className="p-1">
            {loading ? (
              <div className="text-center">
                <Spinner color="info" />
              </div>
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={true}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, errors, dirty }) => {
                  const isErrors = !isEmpty(errors);
                  const titleIsEmpty = values?.[formFields.title] === "";
                  const descriptionIsEmpty =
                    values?.[formFields.category] === "";
                  const linkIsEmpty = values?.[formFields.price] === "";

                  const isDisableUpdate =
                    isErrors ||
                    titleIsEmpty ||
                    descriptionIsEmpty ||
                    linkIsEmpty ||
                    loading ||
                    !dirty;

                  return (
                    <form>
                      <div className={styles.formWrapper}>
                        <div className={styles.leftPart}>
                          <OnboardingLabel
                            title="Video link"
                            fieldName={formFields.link}
                          />
                          <OnboardingLabel
                            title="Onboarding Heading"
                            fieldName={formFields.title}
                          />
                          <OnboardingLabel
                            title="Onboarding Description"
                            fieldName={formFields.description}
                          />
                        </div>
                        <div className={styles.rightPart}>
                          <OnboardingInput
                            name={formFields.link}
                            placeholder="Add youtube link of your video"
                          />
                          <OnboardingInput
                            name={formFields.title}
                            placeholder="Enter heading"
                          />
                          <OnboardingInput
                            name={formFields.description}
                            placeholder="Enter description..."
                            isTextarea
                          />
                          <button
                            className={styles.button}
                            disabled={isDisableUpdate}
                            onClick={(event) => handleSubmit(event, values)}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            )}
          </CardBody>
        </Card>
      </Container>
    </section>
  );
};

export default Onboarding;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import { Formik } from "formik";
import moment from "moment";

import FormikInput from "../../../../../../base/components/FormikInput";
import CustomDatePicker from "../../../../../../base/components/Datepicker";
import { AvatarUploader } from "../../../../../../features/AvatarUploader";

import { validationSchema } from "./form";

const EditUser = ({ user, onEditUser, isLoading }) => {
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    setSelectedImage(user.avatar);
  }, []);

  return (
    <Card className="p-2">
      <Formik
        initialValues={user}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onEditUser(values);
        }}
      >
        {({ errors, handleSubmit, setFieldValue }) => (
          <form className="form-horizontal p-2" onSubmit={handleSubmit}>
            <div className="edit-avatar-wrapper">
              {/* TODO add image-cropper */}
              <AvatarUploader
                selectedImage={selectedImage}
                onChange={(image) => {
                  const imageUrl = URL.createObjectURL(image);
                  setSelectedImage(imageUrl);
                  setFieldValue("avatar", imageUrl);
                }}
              />
            </div>
            <FormikInput
              id="firstName"
              label="First name"
              name="firstName"
              containerClassName="mb-3"
              type="text"
              placeholder="Enter first name"
            />
            <FormikInput
              id="lastName"
              label="Last name"
              name="lastName"
              containerClassName="mb-3"
              type="text"
              placeholder="Enter last name"
            />
            <FormikInput
              id="email"
              label="Email"
              name="email"
              containerClassName="mb-3"
              readOnly
              type="text"
            />
            <CustomDatePicker
              handleChangeDate={(date) =>
                setFieldValue("dateOfBirth", moment(date).format("D/MM/YYYY"))
              }
              initialValue={moment(user.dateOfBirth).format("D/MM/YYYY")}
              format="d/m/Y"
            />
            <button
              className="btn btn-primary btn-block waves-effect waves-light mt-3 w-100"
              type="submit"
              disabled={Object.keys(errors).length || isLoading}
            >
              Confirm
            </button>
          </form>
        )}
      </Formik>
    </Card>
  );
};

EditUser.propTypes = {
  user: PropTypes.object.isRequired,
  onEditUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default EditUser;

import * as yup from "yup";
import {PASSWORD_PATTERN} from "../../../../base/constants/patterns";
import {USER_PASSWORD} from "../../../../validation/user";


export const validationSchema = yup.object().shape({
	oldPassword: yup.string()
		.required(),
	password: USER_PASSWORD
		.required(),
	newPassword: yup.string()
		.required()
		.matches(PASSWORD_PATTERN)
		.test('passwords-match', 'Passwords must match', function (value) {
			return this.parent.password === value
		}),
});

export const initialValues = {
	oldPassword: '',
	password: '',
	newPassword: '',
};
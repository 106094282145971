
const LINK_TO_PROFILE_ROOT = `/app/profile`;
const LINK_TO_CHANGE_PASSWORD_PAGE = `${LINK_TO_PROFILE_ROOT}/settings`;
const LINK_TO_CHANGE_AVATAR_PAGE = `${LINK_TO_PROFILE_ROOT}/avatar`;
const LINK_TO_VIEW_PROFILE_PAGE = `${LINK_TO_PROFILE_ROOT}/info`;

export const PROFILE_GROUP_LINKS = {
	BASE: LINK_TO_PROFILE_ROOT,
	LINK_TO_CHANGE_PASSWORD_PAGE,
	LINK_TO_CHANGE_AVATAR_PAGE,
	LINK_TO_VIEW_PROFILE_PAGE
};
import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import moment from "moment";

const SecondaryUserInfo = ({ dateOfBirth }) => {
  return (
    <Card>
      <CardBody>
        <label>Birthday</label>
        <p>{moment(dateOfBirth).format("D/MM/YY")}</p>
      </CardBody>
    </Card>
  );
};

SecondaryUserInfo.propTypes = {
  dateOfBirth: PropTypes.string.isRequired,
};

export default SecondaryUserInfo;

const LINK_AUTH = "/auth";

export const AUTH_GROUP_LINKS = {
  BASE: LINK_AUTH,
  LINK_LOGIN: `${LINK_AUTH}/login`,
  LINK_LOG_OUT: `${LINK_AUTH}/logout`,
  LINK_FORGOT_PASSWORD: `${LINK_AUTH}/forgot-password`,
  LINK_RESET_PASSWORD: `/restore-admin-password`,
  LINK_REGISTER: `${LINK_AUTH}/register`,
  LINK_VERIFICATION: `${LINK_AUTH}/verification`,
  LINK_BLOCKED_ACCOUNT: `${LINK_AUTH}/blocked-account`,
  EXTERNAL: {
    TERMS_OF_USE: `/privacy`,
    AFTER_LOGIN: "/app",
  },
};

export const MIN_TITLE = 1;
export const MAX_TITLE = 50;
export const TITLE_ERROR = "Heading must be from 1 to 50 characters";

export const MIN_DESCRIPTION = 1;
export const MAX_DESCRIPTION = 500;
export const DESCRIPTION_ERROR = "Description must be from 1 to 500 characters";

export const MIN_LINK = 1;
export const MAX_LINK = 200;
export const LINK_LENGTH_ERROR = "Link must be from 1 to 200 characters";
export const LINK_FORMAT_ERROR =
  "The link should start from https://youtu.be or https://www.youtube.com and must not contain space";
export const LINK_PATTERN =
  /^(https:\/\/youtu\.be+)|(https:\/\/www\.youtube\.com\/)[\S]+$/;

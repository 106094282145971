import React, { useState, useCallback } from "react";
import {AvatarUploader} from "../../../../features/AvatarUploader";
import {ImageCropper} from "../../../../features/ImageCropper";
import {useService} from "../../../../base/hooks/useService";
import ProfileService from "../../../../services/ProfileService";
import S3Service from "../../../../services/S3Service";
import {formatBytes} from "../../../../features/ImageCropper/helpers";
import {useModal} from "../../../../base/hooks/useModal";
import {useFileToURL} from "../../../../base/hooks/useFileToURL";


const ChangeAvatar = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  /**
   * @type {S3Service}
   */
  const imageService = useService(S3Service);
  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);
  const {fileToURL} = useFileToURL();
  const {Modal, close, open, getModalProps} = useModal();

  const onChangeAvatar = useCallback(
    (file) => {
      setSelectedImage({
        ...file,
        preview: fileToURL(file),
        formattedSize: formatBytes(file.size),
      });

      open();
    },
    [setSelectedImage, fileToURL]
  );

  const onCropImage = useCallback(async (croppedImage) => {
    const croppedImageBlobUrl = URL.createObjectURL(croppedImage);

    setCroppedImage(croppedImageBlobUrl);
    setSelectedImage(null);

    close();

    const uploadData = await imageService.uploadImageFiles([croppedImage]);
    const imageId = uploadData[0].file.id;

    if (!imageId) return;

    await profileService.createAvatar(imageId);
  }, []);

  const onCloseCropper = useCallback(() => {
    close()
  }, [close]);


  return (
      <div className="page-content">
          <AvatarUploader
              selectedImage={croppedImage}
              onChange={onChangeAvatar}
          />

          <Modal {...getModalProps()}>
              <Modal.Header {...getModalProps()}>Test</Modal.Header>
              <ImageCropper
                  selectedImage={selectedImage}
                  onCropImage={onCropImage}
                  onCloseCropper={onCloseCropper}
                  isRoundedCropper
              />
          </Modal>
      </div>
  );
};

export default ChangeAvatar;

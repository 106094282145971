/* eslint-disable react/prop-types */
import React from "react";
import { parseQuery } from "../../../../base/hooks/useQueryString";

import { ReactComponent as IconDefault } from "../../../../base/components/Table/icons/ic_sort-alt_DEFAULT.svg";
import { ReactComponent as IconAsc } from "../../../../base/components/Table/icons/ic_sort-ASC.svg";
import { ReactComponent as IconDesc } from "../../../../base/components/Table/icons/ic_sort-DESC.svg";

const DESC_SORT = "desc";

const HeaderFormatter = ({ text, dataField, sortBy: sortByField }) => {
  const { orderBy = "", orderType = DESC_SORT } = parseQuery(location.search);

  const getSortIcon = (cellName) => {
    if (orderBy === cellName) {
      return orderType !== DESC_SORT ? IconAsc : IconDesc;
    }
    return IconDefault;
  };

  const Icon = getSortIcon(dataField || sortByField);

  return (
    <>
      {text} <Icon className={"table-sort-icon"} />
    </>
  );
};

export default HeaderFormatter;

import PropTypes from "prop-types";
import React from "react";

import { Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

import AuthRoutes from "./groups/auth";
import ContactRoutes from "./groups/contact";

import PrivateRoute from "./base/components/PrivateRoute";
import Page404 from "./base/pages/404";

import "./assets/scss/theme.scss";

import Privacy from "./groups/privacy";
import AppRoutes from "./groups/app";
import ErrorBoundary from "./base/components/ErrorBoundary";
import { PRIVACY_GROUP_LINKS } from "./groups/privacy/config";
import { AUTH_GROUP_LINKS } from "./groups/auth/config";
import { APP_GROUP_LINKS } from "./groups/app/config";
import { BASE_GROUP_LINKS } from "./base/config";
import { CONTACT_US_GROUP_LINKS } from "./groups/contact/config";
import ResetPassword from "./groups/auth/pages/ResetPassword";
import { LINK_TO_STORE_OWNERS } from "./base/constants/links";

const App = () => {
  return (
    <ErrorBoundary>
      <Switch>
        {/* Auth */}
        <Route path={AUTH_GROUP_LINKS.BASE} component={AuthRoutes} />

        {/* App */}
        <PrivateRoute path={APP_GROUP_LINKS.BASE} component={AppRoutes} />
        <Redirect from="/" to={LINK_TO_STORE_OWNERS} exact />

        {/* Privacy policy */}
        <Route path={PRIVACY_GROUP_LINKS.BASE} component={Privacy} />

        {/* Contact Us */}
        <Route
          path={CONTACT_US_GROUP_LINKS.BASE}
          component={ContactRoutes}
          exact
        />

        <Route
          path={AUTH_GROUP_LINKS.LINK_RESET_PASSWORD}
          component={ResetPassword}
        />

        {/* Not found page */}
        <Route path={BASE_GROUP_LINKS.LINK_404} component={Page404} />
        <Redirect to={BASE_GROUP_LINKS.LINK_404} />
      </Switch>
    </ErrorBoundary>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

AppRoutes.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps)(App);

import React from "react";
import PropTypes from "prop-types"

export const createStatelessProvider = (initialState) => {
    const State = React.createContext(initialState);

    const Provider = ({children, value = initialState}) => {
        const [state] = React.useState(value);

        return (
            <State.Provider value={state}>
                {children}
            </State.Provider>
        );
    };

    Provider.propTypes = {
        children: PropTypes.any,
        value: PropTypes.any,
    };

    return {
        State,
        Provider
    }
};

export const createStatefullProvider = (initialState) => {
    const State = React.createContext(initialState);
    const DispatchContext = React.createContext();

    const Provider = ({children, value = initialState}) => {
        const [state, update] = React.useState(value);

        return (
            <DispatchContext.Provider value={update}>
                <State.Provider value={state}>
                    {children}
                </State.Provider>
            </DispatchContext.Provider>
        );
    };

    Provider.propTypes = {
        children: PropTypes.any,
        value: PropTypes.any,
    };

    return {
        State,
        DispatchContext,
        Provider
    }
};




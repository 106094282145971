import { useParams } from "react-router-dom";
import { useService } from "../../../base/hooks/useService";
import StoreOwnersService from "../../../services/StoreOwnersService";
import { useCallback, useEffect, useState } from "react";

export const useStoreOwnersBillingHistory = (limit = 5) => {
  const { storeOwnerId } = useParams();
  /**
   * @type {StoreOwnersService}
   */
  const storeOwnersService = useService(StoreOwnersService);

  return useCallback(
    ({ offsetBilling: offset }) => {
      return storeOwnersService
        .getStoreOwnerTransaction(storeOwnerId, {
          limit,
          offset,
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
    },
    [storeOwnerId, storeOwnersService, limit]
  );
};

export const useTotalStoreOwnersBillingHistory = () => {
  const load = useStoreOwnersBillingHistory(1);
  const [state, update] = useState({ load: true });

  useEffect(() => {
    load({}).then(() => {
      update({ load: false });
    });
  }, [load]);

  return [state, update];
};

import * as yup from "yup";

import { PASSWORD_PATTERN } from "../base/constants/patterns";
import {
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  MAX_EMAIL_LOCAL_PART_LENGTH,
  MAX_EMAIL_DOMAIN_PART_LENGTH,
  MAX_EMAIL_LENGTH,
} from "./lengthConstants";
import {
  EMAIL_ERROR,
  EMAIL_MAX_LENGTH_ERROR,
  PASSWORD_PATTERN_ERROR,
} from "../base/constants/forms";

export const USER_EMAIL = yup
  .string()
  .trim()
  .email()
  .test("email-validation", EMAIL_MAX_LENGTH_ERROR, (value) => {
    if (!value) return false;

    const [localPart, domainPart] = value.split("@");
    return (
      localPart?.length <= MAX_EMAIL_LOCAL_PART_LENGTH &&
      domainPart?.length <= MAX_EMAIL_DOMAIN_PART_LENGTH
    );
  })
  .max(MAX_EMAIL_LENGTH, EMAIL_ERROR);

export const USER_PASSWORD = yup
  .string()
  .min(MIN_PASSWORD_LENGTH)
  .max(MAX_PASSWORD_LENGTH)
  .matches(PASSWORD_PATTERN, PASSWORD_PATTERN_ERROR)
  .trim();

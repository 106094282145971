import React from "react";
import PropTypes from 'prop-types';

export default function Checkbox({ text, value, onChange, id }) {
  return (
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id={id}
        onChange={onChange}
        checked={value}
      />
      <label className="form-check-label" htmlFor={id}>
        {text}
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  text: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.number,
}
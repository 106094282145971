import React, { useState, useMemo } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, Button } from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import Checkbox from "../../../../base/components/Checkbox";
import { parseQuery } from "../../../../base/hooks/useQueryString";
import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import PropTypes from "prop-types";

const { SearchBar } = Search;

const SEARCH_DELAY = 300;

const FILTER_BY_ALL = "All";
const FILTER_BY_RECURRING = "Recurring";
const FILTER_BY_SINGULAR = "Singular";

const DATE_VALUES = {
  all: 5,
  year: 4,
  threeMonth: 3,
  month: 2,
  week: 1,
};

const DATE_FILTERS = {
  [DATE_VALUES.week]: "Week",
  [DATE_VALUES.month]: "Month",
  [DATE_VALUES.threeMonth]: "ThreeMonth",
  [DATE_VALUES.year]: "Year",
  [DATE_VALUES.all]: "All",
};

const MAX_FILTERS = [FILTER_BY_ALL, FILTER_BY_RECURRING, FILTER_BY_SINGULAR];
const MAX_FILTERS_COUNT = 2;

const ALL_DATE_FILTERS = Object.values(DATE_VALUES);

export default function UsersTableHeader({ toolkitProps }) {
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => parseQuery(location.search), [location]);

  const savedFilter = Object.values(DATE_FILTERS).indexOf(query.Period) + 1;

  const [dateFilters, setDateFilters] = useState(
    savedFilter === DATE_VALUES.all ? ALL_DATE_FILTERS : [savedFilter]
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterOption, setFilterOption] = useState(
    query.PurchaseType ? [query.PurchaseType] : []
  );

  function handleChangeFilters(filter) {
    setFilterOption((prevState) => {
      if (prevState.includes(filter)) {
        return prevState.filter(
          (option) => option !== filter && option !== FILTER_BY_ALL
        );
      }
      const newFilters = [...prevState, filter];

      if (newFilters.length === MAX_FILTERS_COUNT) {
        return MAX_FILTERS;
      }
      return [filter];
    });
  }

  function handleChangeAll() {
    setFilterOption((prevState) => {
      if (prevState.includes(FILTER_BY_ALL)) {
        return [];
      }
      return MAX_FILTERS;
    });
  }

  function selectAllDates() {
    setDateFilters((prevState) => {
      if (prevState.length === ALL_DATE_FILTERS.length) {
        return [];
      }
      return ALL_DATE_FILTERS;
    });
  }

  function selectDateFilter(filter) {
    setDateFilters((prevState) => {
      if (prevState.includes(filter)) {
        return prevState.filter(
          (item) => item !== filter && item !== DATE_VALUES.all
        );
      }

      const newFilters = [...prevState, filter];

      if (newFilters.length >= ALL_DATE_FILTERS.length - 1) {
        return ALL_DATE_FILTERS;
      }
      return newFilters;
    });
  }

  function handleSubmitFilters() {
    setIsFilterOpen((prevState) => !prevState);
    const appliedfilter =
      filterOption.length >= MAX_FILTERS_COUNT ? undefined : filterOption[0];

    let appliedDateFilter = undefined;

    const maxFilter = Math.max.apply(null, dateFilters);

    if (maxFilter > 0) {
      appliedDateFilter = DATE_FILTERS[maxFilter];
    }

    history.push({
      search: qs.stringify({
        ...query,
        PurchaseType: appliedfilter,
        Period: appliedDateFilter,
      }),
    });
  }

  return (
    <section className="d-flex flex-row justify-content-between users-table--filters">
      <h1 className="trainers-list__header">Users</h1>
      <section className="d-flex flex-row">
        <div className="search-box me-4 mb-2 d-inline-block">
          <div className="position-relative">
            <SearchBar
              {...toolkitProps.searchProps}
              className=""
              delay={SEARCH_DELAY}
              placeholder="Search..."
            />
            <i className="bx bx-search-alt search-icon" />
          </div>
        </div>
        <Dropdown
          isOpen={isFilterOpen}
          toggle={() => setIsFilterOpen((prevState) => !prevState)}
        >
          <DropdownToggle
            tag="button"
            className="btn users-table--filters--dropdown__toggle"
          >
            Filter by <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-right ">
            <section className="users-table--filters--dropdown">
              <span className="users-table--filters--dropdown__title ms-2">
                Purchase type:
              </span>

              <Checkbox
                id={"all"}
                onChange={handleChangeAll}
                value={filterOption.includes(FILTER_BY_ALL)}
                text={"All"}
              />
              <Checkbox
                id={"singular"}
                onChange={() => {
                  handleChangeFilters(FILTER_BY_SINGULAR);
                }}
                value={filterOption.includes(FILTER_BY_SINGULAR)}
                text={"Singular"}
              />
              <Checkbox
                id={"recurring"}
                onChange={() => {
                  handleChangeFilters(FILTER_BY_RECURRING);
                }}
                value={filterOption.includes(FILTER_BY_RECURRING)}
                text={"Recurring"}
              />

              <section className="users-table--filters--dropdown__next-section">
                <span className="users-table--filters--dropdown__title ms-2 mb-2">
                  Period:
                </span>

                <Checkbox
                  id={"5"}
                  onChange={selectAllDates}
                  value={dateFilters.includes(DATE_VALUES.all)}
                  text={"All"}
                />

                <Checkbox
                  id={"1"}
                  onChange={() => selectDateFilter(DATE_VALUES.week)}
                  value={dateFilters.includes(DATE_VALUES.week)}
                  text={"1 week"}
                />

                <Checkbox
                  id={"2"}
                  onChange={() => selectDateFilter(DATE_VALUES.month)}
                  value={dateFilters.includes(DATE_VALUES.month)}
                  text={"1 month"}
                />

                <Checkbox
                  id={"3"}
                  onChange={() => selectDateFilter(DATE_VALUES.threeMonth)}
                  value={dateFilters.includes(DATE_VALUES.threeMonth)}
                  text={"3 months"}
                />

                <Checkbox
                  id={"4"}
                  onChange={() => selectDateFilter(DATE_VALUES.year)}
                  value={dateFilters.includes(DATE_VALUES.year)}
                  text={"1 year"}
                />
              </section>
              <Button
                color="success"
                className="w-100 mt-4"
                onClick={handleSubmitFilters}
              >
                Apply
              </Button>
            </section>
          </DropdownMenu>
        </Dropdown>
      </section>
    </section>
  );
}

UsersTableHeader.propTypes = {
  toolkitProps: PropTypes.object,
};

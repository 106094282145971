/* eslint-disable react/prop-types */
import React from "react";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../base/hooks/useHighlight";

const WithHighlight = ({ text, exact = false }) => {
  const {
    params: { query },
  } = useLocationQuery();
  const { decorateText } = useHighlight(query, exact);

  return <p {...decorateText(text)} className={"cell-bold mb-0 ms-3"} />;
};

export default WithHighlight;

import Http from "./Http";

class SettingsService extends Http {
  static $displayName = "SettingsService";

  async getOnboardingInfo() {
    return await this.get("/admins/settings/onboarding");
  }

  async updateOnboardingInfo(values) {
    return await this.put("/admins/settings/onboarding", values);
  }
}

export default SettingsService;

import React from 'react'
import { Container, Card, CardTitle, CardBody } from 'reactstrap'
import PaymentTabs from './components/Tabs';

export default function Settings() {
    return (
        <section className="page-content">
            <Container fluid>
                <Card className="w-50 p-3">
                    <CardTitle>
                        <label>Payment Methods</label>
                    </CardTitle>
                    <CardBody className="p-1">
                        <PaymentTabs />
                    </CardBody>
                </Card>
            </Container>
        </section>
    )
}

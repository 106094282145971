import { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { stringifyParams, parseQuery } from "./useQueryString";
import { useHistory, useLocation } from "react-router-dom";
import { useLoading } from "./useLoading";

/**
 *
 *
 * @param {string} searchQueryParam
 * @param {string} offsetAlias
 * @param {string} sortTypeQueryParam
 * @param {string} sortByQueryParam
 * @param {Function} getNewDataService
 * @param {Function} filterParams
 * @param {Object} reverseOrderMap
 * @param {number} limit
 *
 * @param {Object} sortTransformerMap
 * @param {Object} sortTypeTransdormMap
 *
 * @returns {[boolean, {totalCount: number, nextOffset: number}, data: Array, handleTableChange: Function, actions: Object ]}
 */

export const useTable = ({
  offsetAlias = "offset",
  searchQueryParam = "query",
  sortTypeQueryParam = "sortType",
  sortByQueryParam = "sortBy",
  getNewDataService = () => {},
  reverseOrderMap = {},
  limit = 10,
  sortTransformerMap = null, //for [.net] backend
  sortTypeTransdormMap = null, //for custom sort types asc -> desc -> default -> etc.
  filterParams = (params) => params,
}) => {
  const history = useHistory();
  const { search } = useLocation();

  const newParams = parseQuery(search);

  const afterStringifySearch = stringifyParams(filterParams(newParams));

  const params = useMemo(() => {
    return parseQuery(afterStringifySearch);
  }, [afterStringifySearch]);

  const [loading, { disable, enable }] = useLoading();

  const [pagination, updatePagination] = useState({
    totalCount: 0,
    nextOffset: limit,
  });
  const [data, updateData] = useState([]);
  const refSearch = useRef(params[searchQueryParam] || "");

  const handleTableChange = useCallback(
    (type, { page, searchText = "", sortField, sortOrder }) => {
      const newParams = {
        ...parseQuery(window.location.search),
        [offsetAlias]: limit * (page - 1),
        [searchQueryParam]: searchText
          ? searchText.trimLeft().trimRight()
          : undefined,
        [sortByQueryParam]: sortField ? sortField : undefined,
        [sortTypeQueryParam]: sortField
          ? reverseOrderMap[sortField]
            ? sortOrder
            : sortOrder === "desc"
            ? "asc"
            : "desc"
          : undefined,
      };

      // When changed search - clear offset
      if (refSearch.current !== searchText) {
        newParams[offsetAlias] = 0;
      }

      refSearch.current = searchText;

      if (sortTransformerMap) {
        newParams[sortByQueryParam] = sortTransformerMap[sortField];
      }

      if (sortTypeTransdormMap) {
        newParams[sortTypeQueryParam] = sortTypeTransdormMap[sortOrder];
      }

      history.push({
        search: stringifyParams(newParams),
      });
    },
    [history, limit, params]
  );

  const downloadNewData = useCallback(async () => {
    try {
      enable();
      const { data, pagination } = await getNewDataService({
        ...params,
        limit,
      });
      updateData([...data]);
      updatePagination(pagination);
    } catch (e) {
      disable(false);
    } finally {
      disable(false);
    }
  }, [params]);

  useEffect(() => {
    downloadNewData();
  }, [downloadNewData]);

  const actions = {
    refresh: downloadNewData,
  };

  return [loading, pagination, data, handleTableChange, actions];
};

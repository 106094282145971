import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const StoreOwnerPreview = ({ src, className = "store-owner-preview", alt }) => {
    return src ? (
        <img className={className} src={src} alt={alt} />
    ) : (
        <div className={className} />
    );
};

StoreOwnerPreview.propTypes = {
    src: PropTypes.string,
    className: PropTypes.string,
    alt: PropTypes.string,
};

export default StoreOwnerPreview;
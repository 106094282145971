import Http from "./Http";


class ProfileService extends Http {
  static $displayName = "ProfileService";

  async changePassword(values) {
    return await this.put("/admins/password", values);
  }

  async editProfile(values) {
    return await this.put("/users/me", values);
  }

  async createAvatar(fileId) {
    return await this.post("/users/avatar", { fileId });
  }

  async getProfileInfo() {
    return await this.get("/users/me");
  }
}

export default ProfileService;

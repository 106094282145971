import React from "react";
import HeaderFormatter from "../Table/HeaderFormatter";
import StoreOwnerPreview from "../StoreOwnerPreview";
import WithHighlight from "../WithHighlight";
import {
  FormatAction,
  FormatActiveSubscription,
  FormatDate,
} from "../../helpers/format";
import { DATE_FORMAT } from "../../constants/table";

export function TableStructure() {
  return [
    {
      text: "User ID",
      dataField: "id",
      formatter: (cellContent) => {
        return <span className="font-weight-semibold">{cellContent}</span>;
      },
    },
    {
      text: "Name",
      headerFormatter: HeaderFormatter,
      formatter: (_, { firstName, lastName, image }) => {
        const name = firstName + " " + lastName;
        return (
          <div className="d-flex align-items-center font-weight-normal">
            <StoreOwnerPreview src={image?.link} />
            <WithHighlight text={name || "-"} />
          </div>
        );
      },
      sort: true,
      dataField: "name",
    },
    {
      text: "Join",
      dataField: "createdAt",
      formatter: (_, { createdAt }) => {
        return FormatDate(createdAt, DATE_FORMAT);
      },
    },
    {
      text: "Last Activity",
      dataField: "lastActivityAt",
      formatter: (_, { lastActivityAt }) => {
        return FormatDate(lastActivityAt, DATE_FORMAT);
      },
    },

    {
      text: "Subscription",
      dataField: "activeSubscription.type",
      formatter: (cellContent, rowContent) =>
        FormatActiveSubscription(rowContent.registrationStatus, cellContent),
    },
    {
      text: "",
      headerFormatter: () => (
        <div className="d-flex justify-content-center">Action</div>
      ),
      dataField: "isBlocked",
      formatter: (_, { id, isBlocked }) => {
        return (
          <div
            className={"table-actions"}
            onClick={(event) => event.stopPropagation()}
          >
            <FormatAction id={id} isBlocked={isBlocked} />
          </div>
        );
      },
    },
  ];
}

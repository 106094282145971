import React from "react";
import { Switch, Route } from "react-router-dom";

import { UsersList } from "./pages/UsersList/";
import UserInfo from "./pages/UserInfo";

import {USERS_GROUP_LINKS} from "./config";

const Users = () => {
  return (
    <Switch>
      <Route path={USERS_GROUP_LINKS.BASE} component={UsersList} exact />
      <Route path={USERS_GROUP_LINKS.LINK_USER_DETAILS} component={UserInfo} exact />
    </Switch>
  );
};

export default Users;

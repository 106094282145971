import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import StoreOwners from "./pages/StoreOwners";
import StoreOwnerById from "./pages/StoreOwnerById";
import {LINK_TO_STORE_OWNER_BY_ID, LINK_TO_STORE_OWNERS} from "../../base/constants/links";

const StoreOwnersRoutes = () => {
    return (
        <Switch>
            <Route path={LINK_TO_STORE_OWNER_BY_ID} component={StoreOwnerById}/>
            <Route path={LINK_TO_STORE_OWNERS} component={StoreOwners} exact/>
            <Redirect to={LINK_TO_STORE_OWNERS}/>
        </Switch>
    );
};

export default StoreOwnersRoutes;
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useStoreOwners } from "../../hooks/useStoreOwners";
import { useTable } from "../../../../base/hooks/useTable";
import { TableContext } from "../../contexts/table";
import { useStatefullProvider } from "../../../../base/hooks/useProvider";
import Table from "../../../../base/components/Table";
import { LINK_TO_STORE_OWNER_BY_ID } from "../../../../base/constants/links";
import { replaceURLParams } from "../../../../base/helpers/replaceURLParams";
import "./index.scss";
import { TableStructure } from "./columns";
import HeaderComponent from "../TopTablePanel";
import { useUpdateBlockedStatusStoreOwner } from "../../hooks/useUpdateBlockedStatusStoreOwner";

const LIMIT = 10;

const reverseOrderMap = { createdAt: true };

const StoreOwnersList = () => {
  const history = useHistory();
  const { params } = useLocationQuery();

  const getCollection = useStoreOwners();

  const [loading, pagination, list, handleTableChange, { refresh }] = useTable({
    searchQueryParam: "query",
    sortTypeQueryParam: "orderType",
    sortByQueryParam: "orderBy",
    reverseOrderMap,
    getNewDataService: getCollection,
    limit: LIMIT,
  });

  const [, updateTableContext] = useStatefullProvider(TableContext);

  const { onUpdateBlockedStatus, viewUpdateBlockedStatus } =
    useUpdateBlockedStatusStoreOwner(
      useCallback(() => {
        refresh();
      }, [refresh, updateTableContext])
    );

  useEffect(() => {
    updateTableContext({
      onUpdateBlockedStatus,
      used: pagination.totalCount,
    });
  }, [updateTableContext, pagination.totalCount, onUpdateBlockedStatus]);

  return (
    <div className={"store-owners-table"}>
      {viewUpdateBlockedStatus}

      <Table
        data={list}
        dataStructure={TableStructure}
        loading={loading}
        HeaderComponent={HeaderComponent}
        limit={LIMIT}
        offset={params.offset || 0}
        totalCount={pagination.totalCount}
        handleTableChange={handleTableChange}
        noDataMessage={"No matches were found"}
        onRowClick={({ id }) => {
          history.push(
            replaceURLParams(LINK_TO_STORE_OWNER_BY_ID, {
              storeOwnerId: id,
            })
          );
        }}
      />
    </div>
  );
};

export default () => {
  return (
    <TableContext.Provider>
      <StoreOwnersList />
    </TableContext.Provider>
  );
};

import React, { useCallback, useEffect, useState } from "react";

import CustomButton from "../CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../CustomModal";

import StorageService from "../../../services/StorageService";
import { useService } from "../../hooks/useService";

import "./index.scss";
import { KEY_USER } from "../../constants/storage";

const ProfileMenu = () => {
  const [userName, setUserName] = useState("");
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const [isOpenModal, updateIsOpenModal] = useState(false);
  const handleToggleModal = useCallback((_, isOpen = false) => {
    updateIsOpenModal(isOpen);
  }, []);

  useEffect(() => {
    const userInfo = storage.get(KEY_USER, {});
    setUserName(userInfo.firstName);
  }, []);

  return (
    <>
      <span className="d-none d-sm-inline-block profile-menu-wrapper">
        {userName}
      </span>
      <CustomModal isOpen={isOpenModal} onClose={handleToggleModal}>
        <ModalHeader onClose={handleToggleModal}>Log out</ModalHeader>
        <ModalBody>
          <p className="ui-regular">Do you really want to log out?</p>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            className="filled-primary "
            // onClick={() => push(AUTH_GROUP_LINKS.LINK_LOG_OUT)}
          >
            {/* TODO: Navigate to landing page */}
            Ok
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default ProfileMenu;

/* eslint-disable react/prop-types */
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Button from "../../../../../base/components/Button";
import "./index.scss";
import StoreOwnerPreview from "../../StoreOwnerPreview";
import DescriptionBlock from "../../../../../base/components/DescriptionBlock";
import { useDeleteStoreOwner } from "../../../hooks/useDeleteStoreOwner";
import { useHistory, useParams } from "react-router-dom";
import { LINK_TO_STORE_OWNERS } from "../../../../../base/constants/links";

const OwnerCard = ({
  image,
  firstName,
  lastName,
  email,
  phoneNumber,
  card,
}) => {
  const { storeOwnerId } = useParams();
  const history = useHistory();

  const { viewDelete, onDelete, deleting } = useDeleteStoreOwner(() => {
    history.push(LINK_TO_STORE_OWNERS);
  });

  const handleDelete = () => onDelete(storeOwnerId);

  return (
    <Card>
      {viewDelete}
      <CardBody>
        <Row className={"d-flex justify-content-between mb-3"}>
          <Col xs={9}>
            <Row>
              <Col xs={2}>
                <StoreOwnerPreview
                  src={image?.link}
                  className={"store-owner-avatar"}
                />
              </Col>
              <Col xs={10}>
                <p className="font-size-16 font-weight-medium text-truncate mb-1">
                  {firstName} {lastName}
                </p>
                <p className="font-size-13 font-weight-normal text-muted mb-0">
                  {email}
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={3} className={"d-flex justify-content-end"}>
            <Button
              color={"danger"}
              className={"delete-btn"}
              onClick={handleDelete}
              disabled={deleting}
            >
              Delete
            </Button>
          </Col>
        </Row>
        <Row className="dropdown-divider" />
        <Row className={"mt-3"}>
          <DescriptionBlock title={"Email:"} value={email} />
          <DescriptionBlock title={"Phone number:"} value={phoneNumber} />
          <DescriptionBlock title={"Credit card:"} value={card} />
        </Row>
      </CardBody>
    </Card>
  );
};

export default OwnerCard;

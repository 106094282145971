import React, { useState } from 'react';
import { Col, CardBody, Card, Alert, Container } from "reactstrap";
import { Formik } from 'formik';

import FormikInput from '../../../../base/components/FormikInput';
import {useService} from "../../../../base/hooks/useService";
import ProfileService from "../../../../services/ProfileService";
import {initialValues, validationSchema} from "./form";
import {useLoading} from "../../../../base/hooks/useLoading";

export default function Settings() {
    const [successRequestMessage, setSuccessRequestMessage] = useState(false);
    const [isLoading, {registerPromise}] = useLoading();

    /**
     * @type {ProfileService}
     */
    const profileService = useService(ProfileService);

    const changePassword = ({password, newPassword}) => {
        registerPromise(
          profileService.changePassword({
              password,
              newPassword,
          })
        )
          .then(() => {
              setSuccessRequestMessage(true);
          })
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Col lg={6} xl={4}>
                    <Card className="card-auth overflow-hidden">
                        <CardBody>
                            <label className="font-size-16 font-weight-bold p-2">Change password</label>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    changePassword(values)
                                }}
                            >
                                {({
                                    errors,
                                    handleSubmit,
                                }) => (

                                    <form
                                        className="form-horizontal p-2"
                                        onSubmit={handleSubmit}
                                    >

                                        <FormikInput
                                            id="oldPassword"
                                            label="Old password"
                                            name="oldPassword"
                                            containerClassName="mb-3"
                                            type="password"
                                            placeholder="Enter old password"
                                        />

                                        <FormikInput
                                            id="password"
                                            label="New password"
                                            name="password"
                                            containerClassName="mb-3"
                                            type="password"
                                            placeholder="Enter new password"
                                        />

                                        <FormikInput
                                            id="newPassword"
                                            label="Confirm password"
                                            name="newPassword"
                                            containerClassName="mb-3"
                                            type="password"
                                            placeholder="Enter confirm password"
                                        />
                                        <button
                                            className="btn btn-primary btn-block waves-effect waves-light mt-3 w-100"
                                            type="submit"
                                            disabled={
                                                isLoading ||
                                                Object.keys(errors).length
                                            }
                                        >
                                            Confirm
                                </button>

                                    </form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                    {successRequestMessage ? <Alert color="success"><span className="success-alert-message">Password successfully changed</span></Alert> : null}
                </Col>
            </Container>
        </div>
    );
}

import React, {useCallback} from "react";
import {CardBody, Card, Alert} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import profileImg from "../../assets/profile-img.png";
import FormikInput from "../../../../base/components/FormikInput";
import {Formik} from "formik";
import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";
import Button from "../../../../base/components/Button/index";
import SocialLogin from "../../../../features/SocialLogin/index";

import {
	IS_GOOGLE,
	IS_FACEBOOK,
} from "../../../../features/SocialLogin/constants/index";
import {BUTTON_COLORS, BUTTON_STYLES} from "../../../../base/components/Button/appearance";
import {useService} from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import {useLoading} from "../../../../base/hooks/useLoading";
import {useRequestErrorMessage} from "../../../../base/hooks/useRequestErrorMessage";
import {initialValues, validationSchema} from "./form";
import {AUTH_GROUP_LINKS} from "../../config";


const Register = () => {
	/**
	 * @type {AuthService}
	 */
	const authService = useService(AuthService);

	const authServiceMethods = {
		[IS_GOOGLE]: (token) => authService.registerLoginWithGoogle(token),
		[IS_FACEBOOK]: (token) => authService.registerLoginWithFacebook(token),
	};
	const [alertText, {onCatchError}] = useRequestErrorMessage();
	const history = useHistory();
	const [isLoading, {registerPromise}] = useLoading();

	const registerUser = (values) => {
		registerPromise(authService.register(values))
			.catch(onCatchError)
			.then(() => history.push(AUTH_GROUP_LINKS.LINK_LOGIN))
	};

	const onSuccess = useCallback((token, socialType) => {
			const authMethod = authServiceMethods[socialType];

			registerPromise(authMethod(token))
				.catch(onCatchError)
				.then(() => history.push(AUTH_GROUP_LINKS.LINK_LOGIN))

		},
		[history, registerPromise]);

	const onFail = useCallback(() => {
		// TODO error message
	}, []);

	return (
		<AuthCardContainer metaText="Register">
			<Card className="overflow-hidden">
				<AuthHeader
					headerText="Free Register"
					description="Get your free account now."
					icon={profileImg}
				/>

				<CardBody className="pt-0">
					<Logo/>

					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={registerUser}
					>
						{({errors, handleSubmit}) => (
							<form className="form-horizontal p-2" onSubmit={handleSubmit}>
								{alertText && <Alert color="danger">{alertText}</Alert>}

								<FormikInput
									id="email"
									name="email"
									label="Email"
									containerClassName="mb-3"
									placeholder="Enter email"
									type="email"
								/>

								<FormikInput
									id="password"
									label="Password"
									name="password"
									containerClassName="mb-3"
									type="password"
									placeholder="Enter password"
								/>

								<FormikInput
									id="confirmPassword"
									label="Confirm password"
									name="confirmPassword"
									containerClassName="mb-3"
									type="password"
									placeholder="Enter password"
								/>

								<Button
									color={BUTTON_COLORS.primary}
									type="submit"
									block={BUTTON_STYLES.block}
									className="mt-2"
									disabled={isLoading || !!Object.keys(errors).length}
								>
									Register
								</Button>
								<SocialLogin
									onSuccess={onSuccess}
									onFail={onFail}
									isRegister={true}
								/>
							</form>
						)}
					</Formik>
					<div className="mt-4 text-center">
						<p className="mb-0">
							By registering you agree to the Project{" "}
							<Link to={AUTH_GROUP_LINKS.EXTERNAL.TERMS_OF_USE} className="text-primary">
								Terms of Use
							</Link>
						</p>
					</div>
				</CardBody>
			</Card>
			<div className="mt-4 text-center">
				<p>
					Already have an account ?{" "}
					<Link
						to={AUTH_GROUP_LINKS.LINK_LOGIN}
						className="font-weight-medium text-primary"
					>
						Login
					</Link>
				</p>
			</div>
		</AuthCardContainer>
	);
};

export default Register;

import axios from "axios";
import { csrfRequestInterceptor } from "./interceptors/csrfRequestInterceptor";

class AxiosApi {
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_BASE_URL}/${
        process.env.REACT_APP_API_VERSION
      }${this.getURLScope()}`,
      withCredentials: true,
    });

    this.api.interceptors.request.use(csrfRequestInterceptor);
  }

  getURLScope() {
    return "";
  }

  async delete(endpointName) {
    return this.api.delete(endpointName);
  }
}

export const logout = async () => {
  const API = new AxiosApi();

  return await API.delete("/admins/sessions");
};

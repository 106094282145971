/* eslint-disable react/prop-types */
import { useLocationQuery } from "../../../../../../base/hooks/useQueryString";
import { useStoreOwnersBillingHistory } from "../../../../hooks/useStoreOwnersBillingHistory";
import { useTable } from "../../../../../../base/hooks/useTable";
import { Spinner } from "reactstrap";
import Table from "../../../../../../base/components/Table";
import React from "react";
import { TableStructure } from "./columns";
import HeaderComponentBillingHistory from "../../../TopTablePanel/BillingHistoryTopTablePanel";
import "./index.scss";

const LIMIT = 5;

const BillingHistoryList = ({ load }) => {
  const { params } = useLocationQuery();

  const getCollection = useStoreOwnersBillingHistory();

  const filterParams = (newParams) => {
    delete newParams["offset"];
    delete newParams["query"];
    return newParams;
  };

  const [loading, pagination, list, handleTableChange] = useTable({
    getNewDataService: getCollection,
    limit: LIMIT,
    offsetAlias: "offsetBilling",
    filterParams: filterParams,
  });

  return (
    <div className={"billing-history-block"}>
      {load ? (
        <div className="text-center">
          <Spinner color="info" />
        </div>
      ) : (
        <div
          className={`billing-history-table ${
            !list.length && "empty-billing-history-table"
          }`}
        >
          <Table
            data={list}
            dataStructure={TableStructure}
            loading={loading}
            HeaderComponent={HeaderComponentBillingHistory}
            limit={LIMIT}
            offset={params.offsetBilling || 0}
            totalCount={pagination.totalCount}
            handleTableChange={handleTableChange}
            noDataMessage={"No billing history"}
          />
        </div>
      )}
    </div>
  );
};

export default BillingHistoryList;

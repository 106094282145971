import React from "react";
import {
  FormatAmount,
  FormatDate,
  FormatSubscription,
} from "../../../../helpers/format";
import {
  BILLING_SUBSCRIPTION_TYPE,
  DATE_FORMAT_BILLING,
} from "../../../../constants/table";

export function TableStructure() {
  return [
    {
      text: "Payment date",
      dataField: "createdAt",
      formatter: (_, { createdAt }) => {
        return FormatDate(createdAt, DATE_FORMAT_BILLING);
      },
      style: {
        width: "45%",
      },
      headerStyle: {
        width: "45%",
      },
    },
    {
      text: "Price",
      dataField: "amount",
      formatter: FormatAmount,
    },
    {
      text: "Subscription",
      dataField: "subscriptionType",
      formatter: (_, { subscriptionType }) => {
        return (
          <div className="billing-subscription">
            {FormatSubscription(BILLING_SUBSCRIPTION_TYPE, subscriptionType)}
          </div>
        );
      },
      style: {
        width: "15%",
      },
      headerStyle: {
        width: "15%",
      },
    },
  ];
}

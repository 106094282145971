import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useLoading} from "../../../../base/hooks/useLoading";
import {useService} from "../../../../base/hooks/useService";
import StoreOwnersService from "../../../../services/StoreOwnersService";
import {Col, Row, Spinner} from "reactstrap";
import OwnerCard from "./OwnerCard";
import SubscriptionCard from "./SubscriptionCard";
import StatisticsCard from "./StatisticsCard";
import BillingHistory from "./BillingHistory";
import EmployeesBlock from "./EmployeesBlock";

const StoreOwnerDetails = () => {
    const [details, setDetails] = useState(null)
    const {storeOwnerId} = useParams()
    const [, {registerPromise}] = useLoading();
    /**
     * @type {StoreOwnersService}
     */
    const storeOwnersService = useService(StoreOwnersService);

    useEffect(() => {
        registerPromise(storeOwnersService.getStoreOwner(storeOwnerId))
            .then(({data}) => {
                setDetails(data)
            })
    }, [storeOwnerId, registerPromise])

    return (
        <div>
            {!details
                ?   <div className='text-center'>
                        <Spinner color='info'/>
                    </div>
                :   <div>
                        <Row>
                            <Col xs={6}>
                                <OwnerCard
                                    image={details.image}
                                    firstName={details.firstName}
                                    lastName={details.lastName}
                                    email={details.email}
                                    phoneNumber={details.phoneNumber}
                                    card={details.card ? `**** **** **** ${details.card.lastFourDigits}` : "-"}
                                />
                            </Col>
                            <Col xs={4}>
                                <SubscriptionCard
                                    type={details.activeSubscription?.type}
                                    createdAt={details.activeSubscription?.createdAt}
                                    registrationStatus={details.registrationStatus}
                                />
                            </Col>
                            <Col xs={2}>
                                <StatisticsCard
                                    inventory={details.activeInventoryCount}
                                    drafts={details.draftsCount}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BillingHistory />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <EmployeesBlock />
                            </Col>
                        </Row>
                    </div>
            }
        </div>
    )
};

export default StoreOwnerDetails;
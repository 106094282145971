import React, {useCallback, useState} from "react";
import {useLoading} from "../../../base/hooks/useLoading";
import {useService} from "../../../base/hooks/useService";
import StoreOwnersService from "../../../services/StoreOwnersService";
import {CustomModal, ModalBody, ModalFooter, ModalHeader} from "../../../base/components/CustomModal";
import Button from "../../../base/components/Button";

const defaultConfig = {
    title: "Delete store owner",
    message: "Are you sure you want to delete this Store Owner? All data will be deleted.",
    btnAccept: "Yes",
    btnClose: "No",
};

export const useDeleteStoreOwner = (afterDelete, {title, message, btnAccept, btnClose} = defaultConfig) => {
    const [ownerToDelete, updateOwnerToDelete] = useState(null);
    const [deleting, {registerPromise}] = useLoading();
    /**
     * @type {StoreOwnersService}
     */
    const storeOwnersService = useService(StoreOwnersService);

    const onDelete = useCallback((id) => {
        updateOwnerToDelete(id)
    }, []);

    const close = useCallback(() => {
        updateOwnerToDelete(null)
    }, []);

    const deleteOwner = useCallback(() => {
        const id = ownerToDelete;

        registerPromise(storeOwnersService.deleteStoreOwner(id)
            .then(() => {
                afterDelete(id)
            }));

        close();
    }, [ownerToDelete, close, storeOwnersService, registerPromise]);

    const viewDelete = (
        <CustomModal isOpen={!!ownerToDelete} onClose={close}>
            <ModalHeader onClose={close}>{title}</ModalHeader>
            <ModalBody>
                <p className='ui-regular'>
                    {message}
                </p>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={close}
                    className={"modal-button"}
                    color="secondary">
                    {btnClose}
                </Button>

                <Button
                    onClick={deleteOwner}
                    className={"modal-button"}
                    color="primary">
                    {btnAccept}
                </Button>
            </ModalFooter>
        </CustomModal>
    );

    return {
        viewDelete,
        deleting,
        onDelete,
    }
}
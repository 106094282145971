import StoreOwnerPreview from "../../../StoreOwnerPreview";
import WithHighlight from "../../../WithHighlight";
import React from "react";

export function TableStructure() {
    return [
        {
            text: "Name",
            formatter: (_, {firstName, lastName, image}) => {
                const name = firstName + " " + lastName;
                return (
                    <div className="d-flex align-items-center font-weight-normal">
                        <StoreOwnerPreview src={image?.link} />
                        <WithHighlight text={name || "-"} />
                    </div>
                )
            },
            dataField: "name",
        },
    ]
}
import React, { useState } from 'react'
import { Card, Col, Container } from 'reactstrap'
import { Formik } from 'formik';
import FormikInput from '../../../../base/components/FormikInput';
import CustomDatePicker from '../../../../base/components/Datepicker';
import { useService } from "../../../../base/hooks/useService";
import ProfileService from "../../../../services/ProfileService";
import {initialValues, validationSchema} from "./form";
import {useLoading} from "../../../../base/hooks/useLoading";

export default function EditProfile() {
    const [isLoading, {registerPromise}] = useLoading();
    const [profileData, setProfileData] = useState(initialValues);
    /**
     * @type {ProfileService}
     */
    const profileService = useService(ProfileService);

    const handleEdit = (values) => {
        registerPromise(profileService.editProfile(values))
          .then(({data}) => {
              setProfileData(prevState => ({
                  ...prevState,
                  ...data,
              }))
          });
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Col md={6}>
                    <Card className="p-2">
                        <label className="font-size-16 font-weight-bold p-2 pb-0">Edit profile</label>

                        <Formik
                            initialValues={profileData}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                handleEdit(values)
                            }}
                        >
                            {({
                                errors,
                                handleSubmit,
                                setFieldValue,
                            }) => (

                                <form
                                    className="form-horizontal p-2"
                                    onSubmit={handleSubmit}
                                >

                                    <FormikInput
                                        id="firstName"
                                        label="First name"
                                        name="firstName"
                                        containerClassName="mb-3"
                                        type="text"
                                        placeholder="Enter first name"
                                    />

                                    <FormikInput
                                        id="lastName"
                                        label="Last name"
                                        name="lastName"
                                        containerClassName="mb-3"
                                        type="text"
                                        placeholder="Enter last name"
                                    />

                                    <FormikInput
                                        id="email"
                                        label="Email"
                                        name="email"
                                        containerClassName="mb-3"
                                        readOnly
                                        type="text"
                                    />

                                    <CustomDatePicker
                                        handleChangeDate={(date) => setFieldValue('birthday', date)}
                                        initialValue={initialValues.birthday}
                                    />
                                    <button
                                        className="btn btn-primary btn-block waves-effect waves-light mt-3 w-100"
                                        type="submit"
                                        disabled={
                                            Object.keys(errors).length || isLoading
                                        }
                                    >
                                        Confirm
                                </button>

                                </form>
                            )}
                        </Formik>
                    </Card>
                </Col>
            </Container>
        </div>
    )
}

import React from "react";
import {Modal} from "reactstrap";
import PropTypes from "prop-types";

export const ModalBody = ({children}) => (<div className="modal-body">{children}</div>);

ModalBody.propTypes = {
    children: PropTypes.any,
};

export const ModalHeader = ({onClose, children}) => (<div className="modal-header">
    <h5 className="modal-title mt-0" id="myModalLabel">
        {children}
    </h5>
    <button
        type="button"
        onClick={onClose}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>);

ModalHeader.propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.any,
};

export const CustomModal = ({isOpen, children}) => {
    return (
        <Modal isOpen={isOpen} size="lg">
            {children}
        </Modal>
    );
};

CustomModal.propTypes = {
    children: PropTypes.any,
    isOpen: PropTypes.bool,
};

import React from "react";
import {Card, Container} from "reactstrap";
import PageMeta from "../../../../base/components/MetaTags";
import StoreOwnersList from "../../components/StoreOwnersList";

const StoreOwners = () => {
    return(
        <div className="page-content">
            <Container fluid>
                <PageMeta title={"Users management"} />
                <h4 className="mb-4 font-weight-semibold font-size-16">USERS MANAGEMENT</h4>
                <Card>
                    <div className={"store-owners-page"}>
                        <StoreOwnersList/>
                    </div>
                </Card>
            </Container>
        </div>
    )
}

export default StoreOwners;
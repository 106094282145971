import React, {useCallback, useState} from "react";
import {useService} from "../../../base/hooks/useService";
import StoreOwnersService from "../../../services/StoreOwnersService";
import {CustomModal, ModalBody, ModalFooter, ModalHeader} from "../../../base/components/CustomModal";
import Button from "../../../base/components/Button";

export const useUpdateBlockedStatusStoreOwner = (afterUpdate) => {
    const [ownerToUpdateStatus, updateOwnerToUpdateStatus] = useState(null);
    const [isBlockedStatus, setIsBlockedStatus] = useState(null)
    /**
     * @type {StoreOwnersService}
     */
    const storeOwnersService = useService(StoreOwnersService);

    const onUpdateBlockedStatus = useCallback((id, isBlocked) => {
        updateOwnerToUpdateStatus(id);
        setIsBlockedStatus(isBlocked)
    }, []);

    const cancel = useCallback(() => {
        updateOwnerToUpdateStatus(null);
    }, []);

    const updateBlockedStatusStoreOwner = useCallback(() => {
        const id = ownerToUpdateStatus;

        storeOwnersService.changeBlockedStatusStoreOwner(id, {isBlocked: !isBlockedStatus}).then(() => {
            afterUpdate(id);
        });

        cancel();
    }, [ownerToUpdateStatus, cancel, storeOwnersService]);

    const viewUpdateBlockedStatus = (
        <CustomModal isOpen={!!ownerToUpdateStatus} onClose={cancel}>
            <ModalHeader onClose={cancel}>{`${isBlockedStatus ? "Unblock" : "Block"} store owner`}</ModalHeader>
            <ModalBody>
                <p className="ui-regular">{`Do you really want to ${isBlockedStatus ? "Unblock" : "Block"} the chosen Store Owner?`}</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={cancel}
                    className={"modal-button"}
                    color="secondary">
                    No
                </Button>

                <Button
                    onClick={updateBlockedStatusStoreOwner}
                    className={"modal-button"}
                    color="primary">
                    Yes
                </Button>
            </ModalFooter>
        </CustomModal>
    );

    return {
        viewUpdateBlockedStatus,
        onUpdateBlockedStatus,
    };
}
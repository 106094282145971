import React, {useMemo} from "react";
import {Card} from "reactstrap";
import {useTotalStoreOwnersEmployees} from "../../../hooks/useStoreOwnersEmployees";
import {TableContext} from "../../../contexts/table";
import Employees from "./Employees";

const EmployeesBlock = () => {
    const [{ total, load }] = useTotalStoreOwnersEmployees();

    const providedData = useMemo(() => {
        return {
            used: total,
        };
    }, [total]);

    return(
        <Card>
            <TableContext.Provider value={providedData}>
                <Employees load={load} total={total}/>
            </TableContext.Provider>
        </Card>
    )
}

export default EmployeesBlock
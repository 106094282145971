import React, { useState, useRef, useEffect, useCallback } from "react";
import { Card, Row, Col, CardBody } from "reactstrap";
import Cropper from "react-cropper";
import classnames from "classnames";
import "cropperjs/dist/cropper.css";


import {
  INITIAL_CROPPER_STATE,
  MAX_IMAGE_WIDTH,
  MAX_IMAGE_HEIGHT,
  MIN_CROP_BOX_WIDTH,
  MIN_CROP_BOX_HEIGHT,
} from "./constants";
import { STRINGS } from "./constants/Strings";
import Button from "../../base/components/Button";
import {BUTTON_COLORS, BUTTON_STYLES} from "../../base/components/Button/appearance";
import PropTypes from "prop-types";

const { CROPPER } = STRINGS;

export const ImageCropper = ({
  selectedImage,
  onCropImage,
  onCloseCropper,
  isRoundedCropper,
}) => {
  if (!selectedImage) return null;

  const [state, setState] = useState(INITIAL_CROPPER_STATE);
  const cropper = useRef(null);

  const cropImage = useCallback(() => {
    if (!cropper?.current?.cropper?.getCroppedCanvas()) return;

    cropper.current.cropper
      .getCroppedCanvas({
        maxWidth: MAX_IMAGE_WIDTH,
        maxHeight: MAX_IMAGE_HEIGHT,
      })
      .toBlob((blob) => onCropImage(blob));
  }, [onCropImage]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      src: !selectedImage ? "" : selectedImage.preview,
    }));
  }, [selectedImage]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">{selectedImage.name}</h4>
                <Row>
                  <Col xl="12">
                    <div
                      className={classnames("img-container mb-2", {
                        "rounded-cropper": isRoundedCropper,
                      })}
                    >
                      <Cropper
                        style={{ height: 400, width: "100%" }}
                        aspectRatio={state.ratio1 / state.ratio2}
                        preview=".img-preview"
                        src={state.src}
                        ref={cropper}
                        zoomTo={state.zoom}
                        dragMode={state.dragMode}
                        rotateTo={state.rotate}
                        scaleX={state.scaleX}
                        scaleY={state.scaleY}
                        enable={state.enable}
                        disable={state.disable}
                        viewMode={state.viewMode}
                        minCropBoxWidth={MIN_CROP_BOX_WIDTH}
                        minCropBoxHeight={MIN_CROP_BOX_HEIGHT}
                        center
                        guides
                      />
                    </div>
                  </Col>
                </Row>
                <Row id="actions">
                  <Col xl="9" className="img-crop-preview-btns docs-buttons">
                    <div className="button-items mt-2">
                      <div className="btn-group btn-group-crop">
                        <Button
                          color={BUTTON_COLORS.success}
                          onClick={cropImage}
                        >
                          <span className="docs-tooltip" id="imgResult1">
                            {CROPPER.cropBtn}
                          </span>
                        </Button>
                      </div>
                      <Button
                        color={BUTTON_COLORS.secondary}
                        onClick={onCloseCropper}
                        wavesEffect={BUTTON_STYLES.wavesEffect}
                      >
                        {CROPPER.closeBtn}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

ImageCropper.propTypes = {
    selectedImage: PropTypes.any,
    isRoundedCropper: PropTypes.bool,
    onCropImage: PropTypes.func,
    onCloseCropper: PropTypes.func,
};

export const ACTIVE_SUBSCRIPTION_TYPE = [
  "",
  "Trial",
  "Starter subscription",
  "Business subscription",
  "Unlimited subscription",
  "",
  "",
  "Beta subscription",
];

export const BILLING_SUBSCRIPTION_TYPE = [
  "",
  "Trial",
  "Starter",
  "Business",
  "Unlimited",
  "",
  "",
  "Beta",
];

export const SUBSCRIPTION_PLAN_FILTER = [
  { label: "All subscription", value: undefined },
  { label: "Trial", value: 1 },
  { label: "Starter subscription", value: 2 },
  { label: "Business subscription", value: 3 },
  { label: "Unlimited subscription", value: 4 },
  { label: "Expired subscription", value: 5 },
  { label: "Pending", value: 6 },
  { label: "Beta", value: 7 },
];

export const DATE_FORMAT_BILLING = "MM/DD/YYYY";
export const DATE_FORMAT = "D MMM, YY";

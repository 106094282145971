/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import "./index.scss";
import { useStatelessProvider } from "../../../../base/hooks/useProvider";
import { TableContext } from "../../contexts/table";
import TableSearch from "../Search";
import { useParams } from "react-router-dom";
import { SUBSCRIPTION_PLAN_FILTER } from "../../constants/table";
import { Col } from "reactstrap";
import Select from "react-select";
import { useFilters } from "../../hooks/useFilters";
import { parseQuery } from "../../../../base/hooks/useQueryString";

const initialFilter = { label: "All subscription", value: undefined };
const filterList = ["subscriptionPlanFilter"];

const HeaderComponent = ({ toolkitProps }) => {
  const [{ used }] = useStatelessProvider(TableContext);
  const { storeOwnerId } = useParams();
  const { mergeQuery } = useFilters(filterList);
  const query = useMemo(() => parseQuery(location.search), [location.search]);

  const getFilterValue = (options, value) => {
    return options.find((element) => element.value === value) || initialFilter;
  };

  return (
    <div
      className={
        "d-flex store-owners-list-toolbar justify-content-between align-items-center"
      }
    >
      <Col className={"d-flex align-items-center align-content-center"}>
        <div className="font-weight-semibold font-size-15 me-4">{`${
          storeOwnerId ? "Employees" : "Users"
        } (${used})`}</div>
        {!storeOwnerId && (
          <div className="form-group select2-container subscription-filter">
            <Select
              name="subscriptionPlanFilter"
              defaultValue={initialFilter}
              options={SUBSCRIPTION_PLAN_FILTER}
              placeholder="Choose"
              onChange={(value) => {
                mergeQuery({
                  subscriptionPlanFilter: value.value,
                });
              }}
              value={getFilterValue(
                SUBSCRIPTION_PLAN_FILTER,
                query["subscriptionPlanFilter"]
              )}
            />
          </div>
        )}
      </Col>

      {!storeOwnerId && (
        <TableSearch {...toolkitProps.searchProps} placeholder="Search..." />
      )}
    </div>
  );
};

export default HeaderComponent;

/* eslint-disable react/prop-types */
import React from "react";
import { Card, CardBody, Row } from "reactstrap";
import DescriptionBlock from "../../../../../base/components/DescriptionBlock";

const StatisticsCard = ({ inventory, drafts }) => {
  return (
    <Card>
      <CardBody>
        <Row className="over-divider mb-3">
          <DescriptionBlock
            title={"Inventory:"}
            value={inventory || 0}
            size={"16"}
          />
        </Row>
        <Row className="dropdown-divider" />
        <Row className="mt-3">
          <DescriptionBlock title={"Drafts:"} value={drafts || 0} size={"16"} />
        </Row>
      </CardBody>
    </Card>
  );
};

export default StatisticsCard;

import React from 'react'
import { parseQuery } from "../../../../base/hooks/useQueryString";
import Icon from '../../../../base/components/Icon';
import moment from 'moment';

const DESC_SORT = 'desc';
const DESC_SORT_ICON = 'sortDesc';
const ASC_SORT_ICON = 'sortAsc';
const SORT_DEFAULT_ICON = 'sortDefault';

function getNameValue(firstName, lastName) {
    return `${firstName} ${lastName}`;
}

function getFirstLetter(string) {
    return string?.length ? string.slice(0, 1) : " ";
}

function formatDate(dateString) {
    const momentObject = moment(dateString)
    return dateString && momentObject.isValid() ? momentObject.format("DD MMM, YYYY") : "-"
}

export default function UsersTableStructure(location) {
    const { sortBy = "", sortType = DESC_SORT } = parseQuery(location.search);

    function getSortIcon(cellName) {
        if (sortBy === cellName) {
            return sortType === DESC_SORT ? ASC_SORT_ICON : DESC_SORT_ICON;
        }
        return SORT_DEFAULT_ICON;
    }

    return [
        {
            dataField: "name",
            sort: true,
            headerFormatter: () => (<>User`s Name <Icon icon={getSortIcon('name')} /></>),
            formatter: (cellContent, user) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        {!user.avatar ? (
                            <div className="avatar-xs me-3">
                                <span className="avatar-title rounded-circle">
                                    {getFirstLetter(user.firstName)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={user.avatar.link}
                                    alt=""
                                />
                            </div>
                        )}
                        <span className="tutee-list--list__name">
                            {getNameValue(user.firstName, user.lastName)}
                        </span>
                    </section>
                )
            }
        },
        {
            dataField: "email",
            sort: true,
            headerFormatter: () => (<>Email <Icon icon={getSortIcon('email')} /></>),
        },
        {
            headerFormatter: () => (<>Date of birth <Icon icon={getSortIcon('birthday')} /></>),
            dataField: "birthday",
            sort: true,
            formatter: (cellContent) => (
                <>
                    {formatDate(cellContent)}
                </>
            )
        },
        {
            dataField: "custom",
            text: "Actions",
            formatter: () => {
                return (
                    <section className="d-flex flex-row justify-content-between align-items-center">

                    <i className={`bx bx-edit font-size-24 cursor-pointer`} onClick={(event) => {
                        event.stopPropagation()
                    } }/>

                    <i className={`bx bx-block font-size-24 cursor-pointer`} onClick={(event) => {
                        event.stopPropagation()
                    } }/>

                    <i className={`bx bx-trash font-size-24 cursor-pointer`} onClick={(event) => {
                        event.stopPropagation()
                    } }/>

                    </section>
                )
            }
        },
    ]
}
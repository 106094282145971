export const SUBSCRIPTION_DATE_FORMAT = "MM/DD/YYYY";
export const DEFAULT_SUBSCRIPTION_DATE_FORMAT = "--/--/----";
export const TRIAL_DAYS = 14;
export const OTHER_DAYS = 28;

export const EXPIRED = {
  price: "--.--",
  title: "Expired",
  color: "red",
};

export const PENDING = {
  price: "--.--",
  title: "Pending",
  color: "orange",
};

const TRIAL = {
  id: 1,
  price: "00.00",
  title: "Trial period",
  color: "blue",
};

const STANDARD = {
  id: 2,
  price: "35.00",
  title: "Starter",
  color: "green",
};

const BUSINESS = {
  id: 3,
  price: "50.00",
  title: "Business",
  color: "green",
};

const UNLIMITED = {
  id: 4,
  price: "65.00",
  title: "Unlimited",
  color: "green",
};

const BETA = {
  id: 7,
  price: "0.00",
  title: "Beta",
  color: "green",
};

export const SUBSCRIPTION_DETAILS = [
  EXPIRED,
  TRIAL,
  STANDARD,
  BUSINESS,
  UNLIMITED,
  BETA,
];

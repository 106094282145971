import { PayPalButton } from "react-paypal-button-v2";
import React from "react";
// https://www.npmjs.com/package/react-paypal-button-v2#large_blue_diamond-usage-example ------- LAST PROPS
export default function Paypal() {
  return (
    <PayPalButton
      amount="9"
      currency="USD"
      // options={{
      //     clientId: process.env.PAYPAL_CLIENT_ID
      // }}
      onSuccess={async (details) => {
        alert("Transaction completed by " + details.payer.name.given_name);

        // OPTIONAL: Call your server to save the transaction
        // return await CredicCardService.createNewPaypalOrder(data.orderID)
      }}
    />
  );
}

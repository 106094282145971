import React, { useState, useEffect } from "react";
import { Card, CardBody, Media } from "reactstrap";

import ProfileService from "../../../../services/ProfileService";
import { useService } from "../../../../base/hooks/useService";

import { DEFAULT_iMAGE } from "../../constants";

 const ProfileInfo = () => {
  const [profileInfo, setProfileInfo] = useState({
    userName: "",
    email: "",
    avatar: "",
  });
  const [,setIsLoading] = useState(false);

  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);

  useEffect(async () => {
    try {
      setIsLoading(true);
      const userData = await profileService.getProfileInfo();
      const { userName, email, avatar } = userData;
      setProfileInfo({ userName, email, avatar: avatar.link });
    } catch (error) {
      // TODO error message
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Card>
      <CardBody>
        <Media>
          <div className="m-3">
            <img
              className="avatar-md rounded-circle img-thumbnail"
              src={profileInfo.avatar || DEFAULT_iMAGE}
              alt={"avatar"}
            />
          </div>
          <Media body className="align-self-center">
            <div className="text-muted">
              <h5>{profileInfo.userName}</h5>
              <p className="mb-1">{profileInfo.email}</p>
            </div>
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
};

export default ProfileInfo

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import ChangePassword from "./pages/ChangePassword";
import ChangeAvatar from "./pages/ChangeAvatar";
import EditProfile from "./pages/EditProfile";
import ProfileInfo from "./pages/ProfileInfo";
import {PROFILE_GROUP_LINKS} from "./config";

export default function ProfileRoutes() {
  return (
    <Switch>
      <Route path={PROFILE_GROUP_LINKS.BASE} component={EditProfile} exact />
      <Route
        path={PROFILE_GROUP_LINKS.LINK_TO_CHANGE_PASSWORD_PAGE}
        component={ChangePassword}
        exact
      />
      <Route path={PROFILE_GROUP_LINKS.LINK_TO_CHANGE_AVATAR_PAGE} component={ChangeAvatar} exact />
      <Route path={PROFILE_GROUP_LINKS.LINK_TO_VIEW_PROFILE_PAGE} component={ProfileInfo} exact />
      <Redirect to={PROFILE_GROUP_LINKS.BASE} />
    </Switch>
  );
}
